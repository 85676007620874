<template>
  <v-container class="fill-height container--fluid grid-list-xl" v-if="loaded">
    <v-row class="justify-center">
      <v-col cols="12" sm="10" md="9" xl="8">
        <v-row class="mb-4">
          <img :src="company.logo_url" :alt="company.name" crossorigin="anonymous" class="pa-3" style="height: 100px;" />
        </v-row>
        <v-card>
          <v-row class="w-100 mx-0">
            <v-col md="6" class="pa-6 align-self-center">
              <LoginCustomerCreateAccount />
              <v-divider class="my-6"></v-divider>
              <v-row class="column">
                <v-col cols="12" class="d-flex align-center pt-3 py-0">
                  <img src="@/assets/img/signin.svg" width="30" alt="connexion-img" class="cancel-flex mr-3" />
                  <h2 class="my-3 text--primary font-weight-regular d-flex align-center">
                    {{ $t('customer_app.login.signin') }}
                  </h2>
                </v-col>
                <v-form v-model="valid" class="px-3">
                  <v-text-field
                    v-model="email"
                    :rules="loginRules"
                    prepend-inner-icon="email"
                    name="login"
                    label="Email"
                    type="email"
                    required
                    @keyup.enter="login"
                    color="primary"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    id="password"
                    prepend-inner-icon="lock"
                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                    @click:append="showPassword = !showPassword"
                    name="password"
                    :label="$t('customer_app.login.password')"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    @keyup.enter="login"
                    color="primary"
                  ></v-text-field>
                </v-form>
              </v-row>
              <div class="text-center">
                <v-btn color="primary" :disabled="!valid" @click="login()">
                  <v-icon left small>arrow_forward</v-icon>
                  {{ $t('customer_app.dashboard.logIn') }}
                </v-btn>
                <div class="flex-div justify-center align-center mt-2">
                  <span @click="forgetPasswordDialog = true" class="caption text-center mt-2">
                    <a>{{ $t('customer_app.login.passwordForget') }}</a>
                  </span>
                  <v-divider vertical class="mx-2 mt-2"></v-divider>
                  <span @click="confirmationEmailDialog = true" class="caption text-center mt-2">
                    <a>{{ $t('customer_app.login.resendEmail') }}</a>
                  </span>
                </div>
              </div>
            </v-col>
            <v-col md="6" class="pa-6" :class="{ 'background-grey': company.dark_mode, 'grey lighten-5': !company.dark_mode }">
              <v-row class="h-100">
                <v-col cols="12">
                  <h2 class="mt-3 primary--text font-weight-regular text-center">
                    Bienvenue dans le programme de fidélité<br />{{ company.name | capitalize }} !
                  </h2>
                  <div class="w-100 my-3" v-if="coreProgram.logo_url">
                    <img :src="coreProgram.logo_url" width="110" alt="register-img" class="d-block mx-auto" />
                  </div>
                  <p class="subtitle-1" v-if="coreProgram.description">{{ coreProgram.description }}</p>
                </v-col>
                <v-col cols="12" class="justify-center py-0">
                  <img
                    v-if="coreProgram.image_description_url"
                    :src="coreProgram.image_description_url"
                    alt="Illustration core program"
                    crossorigin="anonymous"
                    width="100%"
                  />
                  <img
                    v-else-if="!coreProgram.image_description_url && !coreProgram.image_description_text"
                    src="@/assets/img/mobile_login.svg"
                    width="80%"
                    alt
                  />
                </v-col>
                <v-col cols="12" v-if="!coreProgram.image_description_url && coreProgram.image_description_text">
                  <p class="subtitle-1" v-if="coreProgram.description">
                    {{ coreProgram.image_description_text }}
                  </p>
                </v-col>
                <v-col cols="12" class="mt-auto pt-0">
                  <p class="caption text-center mb-2">
                    Nous utilisons le service reCAPTCHA de Google Inc. pour protéger les entrées des formulaires de ce
                    site. Pour plus d'informations concernant la politique de confidentialité de Google, rendez-vous sur
                    <a href="https://policies.google.com/privacy?hl=fr">cette page</a>.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <ForgotPassword v-model="forgetPasswordDialog" :subdomain="$route.params.subdomain" />
      <ConfirmationEmailDialog v-model="confirmationEmailDialog" :subdomain="$route.params.subdomain" />
    </v-row>
  </v-container>
</template>

<script>
import ForgotPassword from '@/components/Dialogs/ForgotPassword'
import ConfirmationEmailDialog from '@/components/Dialogs/ConfirmationEmailDialog'
import LoginCustomerCreateAccount from '@/components/LoginCustomerCreateAccount'
import AuthService from '@/services/AuthService'
import EventBus from '@/packs/event-bus'
import CompanyService from '@/services/CompanyService'
import OwnerService from '@/services/OwnerService'

export default {
  components: {
    ForgotPassword,
    ConfirmationEmailDialog,
    LoginCustomerCreateAccount
  },
  data() {
    return {
      valid: false,
      email: '',
      loginRules: [
        v => !!v || "L'identifiant est requis",
        v => /.+@.+/.test(v) || "L'identifiant doit être une addresse email valide"
      ],
      password: '',
      passwordRules: [v => !!v || 'Le password est requis'],
      showPassword: false,
      identifier: null,
      identifierMask: '#### #### #### #### ####',
      coreProgram: {},
      company: {},
      forgetPasswordDialog: false,
      confirmationEmailDialog: false,
      loaded: false
    }
  },
  computed: {
    subdomain() {
      return this.$route.params.subdomain
    }
  },
  methods: {
    async login() {
      try {
        const res = await AuthService.login(this.email, this.password, this.subdomain)
        sessionStorage.setItem('apiKey', res.api_key)
        this.$router.push(`/${res.api_key}`).catch(err => err)
        EventBus.$emit('showSnackbar', { text: 'Connexion réussie', color: 'success' })
      } catch (err) {
        if (err.response && err.response.status === 401) {
          EventBus.$emit('showSnackbar', { text: 'Identifiant ou mot de passe invalide', color: 'error' })
        }
      }
    }
  },
  async created() {
    if (sessionStorage.getItem('bearer')) {
      this.$router.push(`/${sessionStorage.getItem('apiKey')}`).catch(err => err)
      return
    } else sessionStorage.clear()

    try {
      this.coreProgram = await CompanyService.getCoreProgram(this.subdomain)
      this.company = await OwnerService.get(this.subdomain)
      this.loaded = true
    } catch (err) {
      this.$router.push('/404?errorType=subdomain').catch(err => err)
    }
  }
}
</script>
