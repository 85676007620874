import { setToken, clearToken } from '@/services/AxiosWrapper'
import RequestService from '@/services/RequestService'
import * as Sentry from '@sentry/browser'
import { store } from '@/store/store'
import CompanyService from '@/services/CompanyService'

export default {
  async login(email: string, password: string, subdomain: string): Promise<any> {
    const res = await RequestService.login({ customer: { email, password, subdomain } })
    setToken(res.headers.authorization)
    this.setSentryUser(res.data)
    store.dispatch('setUser', res.data)
    await CompanyService.getCompany(subdomain)
    return res.data
  },

  async register(subdomain: string, customer: any, token: string): Promise<void> {
    const res = await RequestService.register(subdomain, { customer, 'g-recaptcha-response': token })
    return res.data
  },

  async resetPassword(token: string, params: any): Promise<void> {
    await RequestService.resetPassword(token, params)
  },

  async forgotPassword(subdomain: string, email: string, token: string): Promise<void> {
    await RequestService.forgotPassword(subdomain, { email, 'g-recaptcha-response': token })
  },

  async sendConfirmationEmail(subdomain: string, email: string, token: string): Promise<void> {
    await RequestService.confirmationEmail(subdomain, { email, 'g-recaptcha-response': token })
  },

  setSentryUser(user: any): any {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: user.id,
        email: user.email,
        brand: user.brand,
        distributor: user.distributor
      })
    })
  },

  async logout(): Promise<void> {
    await RequestService.logOut()
    store.dispatch('clearUser')
    clearToken()
  }
}
