<template>
  <v-dialog :value="true" @input="$router.push({ name: 'wallet' })" max-width="500">
    <v-card class="py-2">
      <div class="flex-div flex-column align-center pa-4">
        <h2 class="text-center font-weight-light my-3">{{ $t('customer_app.dashboard.logIn') }}</h2>
        <p>{{ $t('customer_app.loginMessage') }}</p>
        <v-form ref="form" v-model="validForm" class="w-100" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="loginRules"
            :value="customer.email"
            prepend-icon="person"
            name="login"
            label="Email"
            type="email"
            required
            @keyup.enter="submit"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            id="password"
            prepend-icon="lock"
            :append-icon="show ? 'visibility' : 'visibility_off'"
            @click:append="show = !show"
            name="password"
            label="Password"
            :type="show ? 'text' : 'password'"
            required
            @keyup.enter="submit"
            color="primary"
          >
          </v-text-field>
        </v-form>
      </div>
      <v-card-actions class="mb-3">
        <v-row class="justify-center">
          <v-col cols="6">
            <v-btn class="w-100 secondary--text" large color="primary" :disabled="!validForm" @click="submit">
              <v-icon left small>arrow_forward</v-icon>{{ $t('customer_app.dashboard.identifier') }}
            </v-btn>
            <span @click="forgetPasswordDialog = true" class="d-block caption text-center mt-2">
              <a>{{ $t('customer_app.login.passwordForget') }}</a>
            </span>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ForgotPassword v-model="forgetPasswordDialog" :subdomain="subdomain_" />
  </v-dialog>
</template>

<script>
import EventBus from '@/packs/event-bus'
import ForgotPassword from '@/components/Dialogs/ForgotPassword'
import AuthService from '@/services/AuthService'

export default {
  components: {
    ForgotPassword
  },
  data() {
    return {
      validForm: true,
      email: '',
      password: '',
      loginRules: [
        v => !!v || `L'identifiant est requis`,
        v => /.+@.+\..+/.test(v) || `L'identifiant doit être une adresse email valide`
      ],
      passwordRules: [v => !!v || 'Le mot de passe est requis'],
      show: false,
      forgetPasswordDialog: false
    }
  },
  computed: {
    customer() {
      return this.$store.state.customer
    },
    owner() {
      return this.$store.state.owner
    },
    subdomain_() {
      return this.owner.company ? this.owner.company.subdomain : this.owner.subdomain
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          await AuthService.login(this.email, this.password, this.subdomain_)
          if (this.$route.query.redirect_to)
            this.$router.push({ path: this.$route.query.redirect_to }).catch(err => err)
          else await this.$router.push({ name: 'wallet' })
          EventBus.$emit('showSnackbar', {
            text: 'Connexion réussie',
            color: 'success'
          })
        } catch (err) {
          if (err.response && err.response.status === 401) {
            EventBus.$emit('showSnackbar', {
              text: 'Identifiant ou mot de passe invalide',
              color: 'error'
            })
          }
        }
      }
    }
  }
}
</script>
