<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="580">
    <v-card class="py-2">
      <v-btn icon class="close-icon-btn" @click.native="$emit('input')">
        <v-icon>close</v-icon>
      </v-btn>
      <div class="flex-div flex-column align-center pa-4">
        <h2 class="text-center my-3">
          {{ $t('customer_app.editEmail.title') }}
        </h2>
        <p class="text-center subtitle-1 my-3">
          {{ $t('customer_app.editEmail.actual') }} <b>{{ customer.email }}</b>
          <br />
        </p>
        <v-form v-model="valid">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            prepend-icon="email"
            name="login"
            label="Nouvelle adresse e-mail"
            type="email"
            required
            @keyup.enter="editEmail"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="email_confirmation"
            :rules="emailConfirmRules"
            prepend-icon="email"
            name="login"
            label="Confirmer votre nouvelle adresse e-mail"
            type="email"
            required
            @keyup.enter="editEmail"
            color="primary"
          ></v-text-field>
        </v-form>
        <v-btn class="mt-4" @click="editEmail()" :disabled="!valid" color="primary">
          {{ $t('customer_app.editEmail.save') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/packs/event-bus'
import CustomerService from '@/services/CustomerService'

export default {
  props: {
    value: {
      type: Boolean
    },
    subdomain: {
      type: String,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      valid: true,
      email: '',
      email_confirmation: '',
      emailRules: [
        v => !!v || `L'adresse e-mail est requise`,
        v => /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(v) || `L'adresse e-mail indiquée n'est pas valide`
      ],
      emailConfirmRules: [
        v => !!v || `La confirmation de l'adresse e-mail est requise`,
        v => v === this.email || 'Les deux adresses e-mail renseignées ne sont pas identiques'
      ]
    }
  },
  computed: {
    customer_() {
      return this.customer
    }
  },
  methods: {
    async editEmail() {
      try {
        let customer = JSON.parse(JSON.stringify(this.customer_))
        customer.email = this.email

        await CustomerService.editByKey(this.$route.params.id, customer)

        this.$emit('emailChanged', this.email)
        this.$emit('input')
        EventBus.$emit('showSnackbar', {
          text: 'Votre adresse email a été modifié, un mail vous a été envoyé afin de la confirmer',
          color: 'success'
        })
      } catch (err) {
        EventBus.$emit('showSnackbar', { text: err, color: 'error' })
      }
    }
  }
}
</script>
