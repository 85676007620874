import AxiosWrapper from '@/services/AxiosWrapper'

const END_POINT = '/customers'

// Customer
const getCustomer = (): any => AxiosWrapper.get('/customer')
const getCustomerByKey = (key: string) => AxiosWrapper.get(`/customer?customer_key=${key}`)
const deleteAccount = (): any => AxiosWrapper.delete(`/customer`)
const editCustomer = (params: any) => AxiosWrapper.put(`/customer`, params)
const editCustomerByKey = (key: string, params: any) => AxiosWrapper.put(`/customer/account?customer_key=${key}`, params)
const editCustomerById = (id: string, params: any) => AxiosWrapper.put(`/customer?customer_key=${id}`, params)
const editCustomerPassword = (id: string, params: any) => AxiosWrapper.put(`/customer/pass?customer_key=${id}`, params)
const login = (params: any) => AxiosWrapper.post(`${END_POINT}/sign_in`, params)
const register = (subdomain: string, params: any) => AxiosWrapper.post(`/companies/${subdomain}/register`, params)
const resetPassword = (token: string, params: any) => AxiosWrapper.put(`/customer/account?reset_password_token=${token}`, params)
const confirmationEmail = (subdomain: string, params: any) => AxiosWrapper.post(`/companies/${subdomain}/resend_confirmation_instructions`, params)
const logOut = (): any => AxiosWrapper.delete(`${END_POINT}/sign_out`)
const getInformations = (params: any) => AxiosWrapper.get('/customer/my_informations', { params })
const acceptTerms = (): any => AxiosWrapper.put('/customer/accept_terms_of_use')
const getTransactions = (perPage: number, page: number, options: string) => AxiosWrapper.get(`/transactions?'?per_page=${perPage}&page=${page}${options}`)
const getOffers = (key: string) => AxiosWrapper.get(`/offers?customer_key=${key}`)
const getOfferById = (offerId: string, id: string) => AxiosWrapper.get(`/offers/${offerId}?customer_key=${id}`)
const getOffersPending = (key: string) => AxiosWrapper.get(`/offers/pending?customer_key=${key}`)
const activateOffer = (offerId: string, id: string) => AxiosWrapper.get(`/offers/${offerId}/activate?customer_key=${id}`)
const getDonationsCustomer = (id: string, params: any) => AxiosWrapper.post(`/donations/${id}/customer`, params)
const getDonationsVouchers = (id: string) => AxiosWrapper.get(`/donations/${id}/voucher`)
const sendDonations = (id: string, params: any) => AxiosWrapper.post(`/donations/${id}/donate`, params)
const updateOptins = (params: any) => AxiosWrapper.put(`/customer/optins`, params)

// Company
const getCompany = (subdomain: string) => AxiosWrapper.get(`/companies/${subdomain}`)
const getCompanySettings = (subdomain: string) => AxiosWrapper.get(`/companies/${subdomain}/setting`)
const signInCompany = (subdomain: string, params: any) => AxiosWrapper.post(`/companies/${subdomain}/sign_in`, params)
const getWelcomeCampaign = (subdomain: string) => AxiosWrapper.get(`/companies/${subdomain}/core_program/timed?by_kind=welcome`)
const getPointOfSales = (): any => AxiosWrapper.get(`/point_of_sales?with_discarded=true`)
const getPointOfSalesBySub = (subdomain: string) => AxiosWrapper.get(`/companies/${subdomain}/point_of_sales`)
const getCoreProgram = (subdomain: string) => AxiosWrapper.get(`/companies/${subdomain}/core_program`)
const forgotPassword = (subdomain: string, params: any) => AxiosWrapper.post(`/companies/${subdomain}/reset_password`, params)
const getDonationsCompany = (id: string) => AxiosWrapper.get(`/donations/${id}/company`)

// Owner
const getOwner = (subdomain: string) => AxiosWrapper.get(`/companies/${subdomain}`)
const getOwnerByKey = (key: string) => AxiosWrapper.get(`/owner?customer_key=${key}`)

export default {
  getCustomer,
  getCustomerByKey,
  deleteAccount,
  editCustomer,
  editCustomerByKey,
  editCustomerById,
  editCustomerPassword,
  login,
  register,
  resetPassword,
  confirmationEmail,
  logOut,
  getInformations,
  acceptTerms,
  getTransactions,
  getOffers,
  getOfferById,
  getOffersPending,
  activateOffer,
  getDonationsCustomer,
  getDonationsVouchers,
  sendDonations,
  updateOptins,

  getCompany,
  getCompanySettings,
  signInCompany,
  getWelcomeCampaign,
  getPointOfSales,
  getPointOfSalesBySub,
  getCoreProgram,
  forgotPassword,
  getDonationsCompany,

  getOwner,
  getOwnerByKey
}
