<template>
  <div v-if="loaded">
    <v-dialog :value="true" @input="$router.go(-1)" fullscreen hide-overlay transition="slide-x-reverse-transition">
      <v-card>
        <v-toolbar dark fixed color="primary">
          <v-btn icon @click="$router.go(-1)" class="secondary--text">
            <v-icon>keyboard_arrow_left</v-icon>
          </v-btn>
          <v-toolbar-title class="secondary--text">CGU/CGV</v-toolbar-title>
        </v-toolbar>
        <div v-if="companySettings.term_of_use" class="mt-5 pt-2">
          <div class="mt-3 pa-4">
            <h2 class="primary--text headline">
              Conditions générales d'utilisation
            </h2>
            <div class="mt-3" v-html="companySettings.term_of_use" />
          </div>
          <div class="mt-3 pa-4" v-if="companySettings.legal_notice">
            <h2 class="primary--text headline">
              Conditions générales de vente
            </h2>
            <div class="mt-3" v-html="companySettings.legal_notice" />
          </div>
        </div>
        <div v-else class="mt-5 pt-5 text-center">
          Aucune conditions générales d'utilisation
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CompanyService from '@/services/CompanyService'

export default {
  data() {
    return {
      companySettings: {},
      loaded: false
    }
  },
  computed: {
    owner() {
      return this.$store.state.owner
    },
    subdomain_() {
      return this.owner.company ? this.owner.company.subdomain : this.owner.subdomain
    }
  },
  async created() {
    if (this.owner) {
      this.companySettings = await CompanyService.getCompanySettings(this.subdomain_)
      this.loaded = true
    }
  }
}
</script>
