<template>
  <v-dialog :value="true" @input="$router.push({ name: 'wallet' })" scrollable fullscreen hide-overlay transition="slide-x-reverse-transition">
    <v-card>
      <v-toolbar fixed color="primary">
        <v-btn icon rigth @click="$router.push({ name: 'wallet' })" class="secondary--text">
          <v-icon>keyboard_arrow_left</v-icon>
        </v-btn>
        <v-toolbar-title class="secondary--text">
          {{ $t('customer_app.dashboard.transactionsHistory') }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-3" ref="container" style="min-height: -webkit-fill-available">
        <TransactionsList v-if="!loading" :refContainer="ref" class="mt-5" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TransactionsList from '@/views/TransactionsList'

export default {
  components: {
    TransactionsList
  },
  data() {
    return {
      ref: null,
      loading: true
    }
  },
  mounted() {
    this.ref = this.$refs.container
    this.loading = false
  }
}
</script>
