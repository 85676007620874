<template>
  <v-dialog :value="true" @input="$router.push({ name: 'wallet' })" max-width="500">
    <v-card v-if="loaded">
      <v-container class="grid-list-lg container--fluid" :class="{ 'mx-0': $vuetify.breakpoint.mdAndDown, 'px-0': $vuetify.breakpoint.mdAndDown }">
        <div class="flex-div flex-column justify-center">
          <template v-if="offer.consumable && offer.consumable.voucher">
            <h1 class="text-center">{{ offer.consumable.voucher_name }}</h1>
            <v-divider></v-divider>
          </template>
          <div class="my-3 text-center w-100">
            <h2 class="font-weight-medium" v-if="offer.consumable">
              {{ offerMessage() }}
            </h2>
            <div v-if="offer.expire_at">
              {{ $t('customer_app.dashboard.expire') }} {{ $moment(offer.expire_at).calendar() }}
            </div>
          </div>
          <div class="img-voucher">
            <img :src="cardImage()" crossorigin="anonymous" />
          </div>
          <div class="mt-3 flex-div justify-center" v-if="offer.consumable.require_activation">
            <v-btn @click.stop="activateOffer()" :disabled="activated" color="primary" append-icon="done">
              {{ activateButtonText }}
              <v-icon v-if="activated" right dark>check_circle</v-icon>
            </v-btn>
          </div>
          <div class="flex-div flex-column align-center mt-3 pa-4">
            <template v-if="offer.voucher">
              <img width="100%" :src="offer.voucher.images.barcode_svg" crossorigin="anonymous" alt="barCode" />
              <h3 class="my-0 py-0 text-center">{{ offer.voucher.formatted_identifier }}</h3>
            </template>
            <template v-else>
              <span class="subtitle-1 d-block text-center">
                Scannez votre carte de fidelité en caisse pour bénéficier de cette offre.
              </span>
            </template>
          </div>
          <v-btn color="primary" v-if="displayDonation" @click="$router.push(`/donate/${owner.subdomain}/${offer.identifier}`)">
            <v-icon v-if="activated" class="mr-2">volunteer_activism</v-icon> Faire un don
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/packs/event-bus'
import CustomerService from '@/services/CustomerService'

export default {
  data() {
    return {
      offer: null,
      loaded: false
    }
  },
  computed: {
    activated() {
      return this.offer.status !== 'pending'
    },
    owner() {
      return this.$store.state.owner.company ? this.$store.state.owner.company : this.$store.state.owner
    },
    activateButtonText() {
      return this.activated ? this.$t('customer_app.dashboard.offerActivated') : this.$t('customer_app.dashboard.activateOffer')
    },
    defaulCartImage() {
      return this.owner.default_cart_image_url ? this.owner.default_cart_image_url : require('@/assets/img/fidelity-voucher.jpg')
    },
    defaulItemImage() {
      return this.owner.default_item_image_url ? this.owner.default_item_image_url : require('@/assets/img/fidelity-voucher.jpg')
    },
    displayDonation() {
      return (this.offer.consumable.voucher && this.offer.splittable && this.offer.donated && this.offer.restricted && this.owner.donation_organization)
    }
  },
  methods: {
    async fetchOffer() {
      this.offer = await CustomerService.getOfferById(this.$route.params.offerId, this.$route.params.id)
      this.loaded = true
      if (this.$route.query.action === 'activate' && !this.activated) await this.activateOffer()
    },
    offerMessage() {
      let message = this.offer.consumable.messages.fr
      const amount = this.offer.consumable.amount / 100
      const remaining_amount = this.offer.remaining_amount ? this.offer.remaining_amount / 100 : null

      return remaining_amount ? message.replace(amount, remaining_amount) : message
    },
    async activateOffer() {
      try {
        await CustomerService.activateOffer(this.offer.id, this.$route.params.id)
        EventBus.$emit('showSnackbar', {text: "L'offre a été activée", color: 'success'})
      } catch (err) {
        EventBus.$emit('showSnackbar', {text: "L'offre n'a pas pu être activée", color: 'error'})
      }
      EventBus.$emit('reloadOffers')
    },
    cardImage() {
      if (this.offer.consumable.target && this.offer.consumable.target.logo_url) return this.offer.consumable.target.logo_url
      else if (this.offer.consumable.scope !== 'cart') return this.defaulItemImage
      else return this.defaulCartImage
    }
  },
  async created() {
    await this.fetchOffer()
  }
}
</script>
