<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex align-center">
        <img src="@/assets/img/signup.svg" width="30" alt="register-img" class="cancel-flex mr-3" />
        <h2 class="my-2 text--primary font-weight-regular d-flex align-center">
          {{ $t('customer_app.login.signup') }}
        </h2>
      </div>
      <div v-if="!create">
        <h4 class="subtitle-1 font-weight-regular">{{ $t('customer_app.login.createAccount') }}</h4>
        <div class="text-center py-5">
          <v-btn @click="create = true" color="primary">
            <v-icon left small>person_add</v-icon> {{ $t('customer_app.login.createAccountBtn') }}
          </v-btn>
        </div>
      </div>
      <div v-else-if="!haveCard">
        <h4 class="subtitle-1 font-weight-regular">Possédez vous déja une carte de fidélité ?</h4>
        <div class="text-center py-5">
          <v-btn class="mx-2" color="primary" @click="haveCard = true"><v-icon left small>check</v-icon> Oui</v-btn>
          <v-btn class="mx-2" color="primary" @click="createAccount()"><v-icon left small>close</v-icon> Non</v-btn>
        </div>
      </div>
      <div v-else>
        <h4 class="subtitle-1 font-weight-regular">{{ $t('customer_app.login.signWithCard') }}</h4>
        <div class="flex-div py-5">
          <v-text-field
            :rules="[v => !!v || $t('sign_in_with_identifier.identifierRequired')]"
            solo
            :label="$t('sign_in_with_identifier.textFieldLabel')"
            v-model="identifier"
            hide-details
            :mask="identifierMask"
          ></v-text-field>
          <v-btn large color="primary" class="button-login-card my-0" @click="sign_in()" :disabled="!identifier">
            <v-icon class="my-auto">arrow_forward</v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EventBus from '@/packs/event-bus'
import CompanyService from '@/services/CompanyService'

export default {
  data() {
    return {
      identifier: null,
      identifierMask: '#### #### #### #### ####',
      create: false,
      haveCard: false
    }
  },
  computed: {
    subdomain() {
      return this.$route.params.subdomain
    }
  },
  methods: {
    async sign_in() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('pass_companies_sign_in')
      try {
        const res = await CompanyService.signInCompany(this.subdomain, {
          identifier: this.identifier,
          'g-recaptcha-response': token
        })
        this.$router.push(`/account/${this.subdomain}/create/new/${res.api_key}`).catch(err => err)
      } catch (err) {
        EventBus.$emit('showSnackbar', {
          text: 'Idenfiant invalide, veuillez vérifier votre saisie.',
          color: 'error'
        })
      }
    },
    createAccount() {
      this.$router.push(`/account/${this.subdomain}/create`).catch(err => err)
    }
  }
}
</script>
