import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from '@/router'

import { store } from '@/store/store'

// ----- VueI18n -------

import i18n from './i18n'

// ----- Vuetify ------

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

Vue.config.productionTip = false

// ----- Vuelidate ------

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// ----- SweetAlert2 ------
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2)

// ----- Moment JS ------
import VueMoment from 'vue-moment'
import moment from 'moment'

Vue.use(VueMoment, { moment })

// ----- V-Calendar ------
import VCalendar from 'v-calendar'
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

// ----- Vue v-mask ------
import VueMask from 'v-mask'
Vue.use(VueMask)

// ----- Vue browser detect plugin ------
import browserDetect from 'vue-browser-detect-plugin'
Vue.use(browserDetect)

// ----- Vue skeleton loader ------
import VueSkeletonLoader from 'skeleton-loader-vue'
Vue.component('vue-skeleton-loader', VueSkeletonLoader)

// ----- Vue ReCaptcha ------
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY
})

//Styles
import '@/assets/stylesheet/customer.sass'
import '@/assets/stylesheet/transactions.sass'
import '@/assets/stylesheet/styles.sass'

Vue.filter('uppercase', function(value: any) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter('capitalize', function(value: any) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

//Loading spinner
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
Vue.component('clip-loader', ClipLoader)

// ------ Sentry ---------
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://fecd382185f540439b5a7c3af4ca9c8f@sentry.io/1508060',
    environment: process.env.VUE_APP_ENV,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      })
    ]
  })
}

import './packs/monetizer.js'

const reqInterceptor = axios.interceptors.request.use(config => {
  return config
})
const resInterceptor = axios.interceptors.response.use(res => {
  return res
})
axios.interceptors.request.eject(reqInterceptor)
axios.interceptors.response.eject(resInterceptor)

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    router,
    store,
    i18n,
    vuetify: new Vuetify({
      theme: {
        dark: true
      },
      icons: {
        iconfont: 'md'
      }
    }),
    render: h => h(App)
  }).$mount('#app')
})
