<template>
  <v-list two-line class="py-0">
    <v-list-item avatar @click="show = !show">
      <v-list-item-avatar>
        <v-icon :class="transaction.event === 'transformation' ? 'icon-orange' : 'icon-green'">
          {{ iconTransaction }}
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content class="pr-3">
        <v-list-item-title>
          {{ titleTransaction }}
          <template v-if="transaction.event === 'ecr'">-
            <span v-if="hasDiscount" style="color: #fabf5e" class="font-weight-bold">
              {{ transaction.new_amount_cart.monetized() }}€
              <v-icon class="info-price">info_outline</v-icon>
            </span>
            <span v-else> {{ transaction.amount_cart.monetized() }}€ </span>
          </template>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ subtitleTransaction }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action class="font-weight-bold" :style="`color: ${!checkBalance ? 'rgb(74, 217, 145)' : ''}`">
        <div style="display: flex">
          <div class="align-self-center mr-1" :style="`color: ${!checkBalance ? 'rgb(74, 217, 145)' : ''}`">
            {{ checkBalance ? '-' : '+' }}
          </div>
          <h3>{{ balanceChange }}</h3>
          <span class="ml-1 caption">{{ transaction.transaction_wallets[0].loyalty_currency_name }}</span>
        </div>
      </v-list-item-action>
    </v-list-item>
    <TransactionsListItemBottomSheet
      :show="show"
      :transaction="transaction"
      :icon="iconTransaction"
      :title="titleTransaction"
      @show="show = $event"
    />
  </v-list>
</template>

<script>
import TransactionsListItemBottomSheet from '@/components/TransactionsListItemBottomSheet'

export default {
  components: {
    TransactionsListItemBottomSheet
  },
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    checkAmount(amount) {
      if (amount) return `Obtention de ${amount.monetized()}€`
      else return 'Récompense'
    }
  },
  computed: {
    checkBalance() {
      return this.transaction.transaction_wallets[0].previous_balance > this.transaction.transaction_wallets[0].balance
    },
    balanceChange() {
      return (
        Math.round(
          (this.transaction.transaction_wallets[0].balance - this.transaction.transaction_wallets[0].previous_balance) *
            100
        ) / 100
      )
    },
    iconTransaction() {
      switch (this.transaction.event) {
        case 'ecr':
          return 'storefront'
        case 'gesture':
        case 'adjustment':
        case 'welcome':
        case 'anniversary':
          return 'card_giftcard'
        case 'transformation':
          return 'loyalty'
        default:
          return 'storefront'
      }
    },
    hasDiscount() {
      return this.transaction.amount_cart !== this.transaction.new_amount_cart
    },
    titleTransaction() {
      const discount = this.transaction.cart.discounts[0]

      switch (this.transaction.event) {
        case 'ecr':
          return 'Achat(s)'
        case 'gesture':
          return 'Geste commercial'
        case 'expiration':
          return 'Expiration'
        case 'transformation': {
          const discountAmount = discount.operation === 'award' ? discount.awarding_reward.amount : discount.amount
          return `Obtention de ${discountAmount.monetized()}€`
        }
        case 'welcome': {
          return `Inscription`
        }
        case 'anniversary':
          return 'Joyeux anniversaire'
        case 'adjustment':
          return 'Trop-perçu'
        default:
          return 'Achat(s)'
      }
    },
    subtitleTransaction() {
      switch (this.transaction.event) {
        case 'ecr':
          return this.transaction.point_of_sale.name
        default:
          return ''
      }
    }
  }
}
</script>
