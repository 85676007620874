<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="dateFormatted"
    transition="scale-transition"
    offset-y
    class="w-100"
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :class="{ 'reward-input': displayWelcomeBadge }"
        v-model="dateFormatted"
        :label="disabled ? $t('common.emptyDate') : label"
        prepend-icon="event"
        :disabled="disabled"
        readonly
        v-on="on"
      ></v-text-field>
    </template>

    <v-date-picker v-model="dateFormatted" no-title scrollable :min="min" :max="max" color="primary">
      <v-spacer></v-spacer>
      <v-btn text @click="menu = false">Cancel</v-btn>
      <v-btn text @click="$refs.menu.save(dateFormatted)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    initialValue: {
      type: [String, Date],
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Default label'
    },
    min: {
      type: String,
      required: false,
      default: undefined
    },
    max: {
      type: String,
      required: false,
      default: undefined
    },
    displayWelcomeBadge: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      menu: false
    }
  },
  computed: {
    dateFormatted: {
      get() {
        return this.initialValue ? this.$moment(this.initialValue).format('YYYY-MM-DD') : undefined
      },
      set(value) {
        this.$emit('update', value)
      }
    }
  }
}
</script>
