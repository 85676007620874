<template>
  <v-bottom-sheet :value="show" @input="$emit('show', false)">
    <v-card class="w-100 pt-3 bottom-sheet-radius">
      <div class="text-center">
        <div
          class="ml-auto mr-auto icon-bg"
          :class="transaction.event === 'transformation' ? 'icon-orange' : 'icon-green'"
        >
          <v-icon>{{ icon }}</v-icon>
        </div>
        <div class="subtitle-1 text-center mt-2">{{ title }}</div>
      </div>
      <div v-if="transaction.event === 'ecr'" class="mb-2 mt-3">
        <div class="headline font-weight-bold text-center">
          <span :class="{ 'old-price': hasDiscount }"> {{ transaction.amount_cart.monetized() }}€ </span>
          <template v-if="hasDiscount">
            <v-icon class="arrow-transformation">arrow_right</v-icon>
            <span style="color: #4AD991"> {{ transaction.new_amount_cart.monetized() }}€ </span>
          </template>
        </div>
      </div>
      <div class="caption text-center mt-1 mb-4">
        {{ $moment(transaction.created_at).format('dddd Do MMMM YYYY') | capitalize }}
        <template v-if="transaction.point_of_sale && transaction.event === 'ecr'">
          <span class="ml-1 mr-2">|</span>{{ transaction.point_of_sale.name }}
        </template>
      </div>
      <v-subheader class="subtitle-1 black--text font-weight-regular">Détails :</v-subheader>
      <div class="px-2">
        <v-timeline align-top dense>
          <v-timeline-item
            v-for="discount in discounts"
            :key="discount.id"
            :color="discount.operation === 'reward' ? '#FFCA83' : '#4AD991'"
            small
            fill-dot
          >
            <v-row class="pt-3">
              <v-col cols="12">
                <h2 class="title mb-1">{{ getType(discount) }}</h2>
                <p class="body-1 font-weight-regular">{{ discount.message ? discount.message : 'Aucune donnée' }}</p>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    transaction: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    getType(discount) {
      if (discount.operation === 'reward') return 'Utilisation'
      else if (discount.operation === 'earn' || discount.operation === 'award') return 'Gain'
      else return 'Unknown'
    }
  },
  computed: {
    hasDiscount() {
      return this.transaction.amount_cart !== this.transaction.new_amount_cart
    },
    discounts() {
      const discounts = []
      if (this.transaction.cart.discounts.length > 0)
        for (const discount of this.transaction.cart.discounts) discounts.push(discount)
      if (this.transaction.cart.items.length > 0) {
        for (const item of this.transaction.cart.items) for (const discount of item.discounts) discounts.push(discount)
      }
      return discounts
    }
  }
}
</script>
