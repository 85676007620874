<template>
  <v-speed-dial class="hidden-md-and-up" v-model="fab" top right direction="bottom" :open-on-hover="false">
    <template v-slot:activator>
      <v-btn fab v-model="fab" small color="grey lighten-4" light>
        <v-icon v-if="!fab">more_horiz</v-icon>
        <v-icon v-else>close</v-icon>
      </v-btn>
    </template>
    <div class="list-flot-btn flex-div flex-column align-end elevation-2 w-100">
      <v-list class="w-100">
        <div class="py-2 px-4">
          <h3 class="pb-2">Fidélité {{ owner.name }}</h3>
          <p class="subheadsubtitle-1ing">Email : {{ customer.email }}</p>
        </div>
        <v-divider></v-divider>
        <v-list-item @click.prevent="$router.push({ name: 'conditionsCustomer' })">
          <v-list-item-avatar>
            <v-icon>info</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>{{ $t('customer_app.dashboard.programInfo') }}</v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click.prevent="$router.push({ name: 'editCustomer' })">
          <v-list-item-avatar>
            <v-icon>edit</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>{{ $t('customer_app.dashboard.edit') }}</v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click.prevent="$router.push({ name: 'historyCustomer' })">
          <v-list-item-avatar>
            <v-icon>history</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>{{ $t('customer_app.dashboard.history') }}</v-list-item-content>
        </v-list-item>
        <template v-if="user">
          <v-divider></v-divider>
          <v-list-item @click.prevent="$router.push({ name: 'settingsCustomer' })">
            <v-list-item-avatar>
              <v-icon>settings</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>{{ $t('customer_app.dashboard.settings') }}</v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click.prevent="logout()">
            <v-list-item-avatar>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>{{ $t('customer_app.dashboard.signOut') }}</v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </v-speed-dial>
</template>

<script>
import AuthService from '@/services/AuthService'

export default {
  data() {
    return {
      fab: false
    }
  },
  computed: {
    owner() {
      return this.$store.state.owner
    },
    customer() {
      return this.$store.state.customer
    },
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    logout() {
      AuthService.logout()
    }
  }
}
</script>
