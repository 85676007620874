<template>
  <v-container class="grid-list-lg container--fluid">
    <v-row class="justify-center">
      <v-col cols="12" sm="6" lg="5" class="mt-4">
        <v-card class="py-3 px-4 flex-div flex-column align-center text-center">
          <h2 class="mt-2 mb-4">{{ $t('customer_app.resetPassword.title') }}</h2>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-text-field
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              :label="$t('customer_app.welcome.password')"
              required
              class="mx-md-4"
              :rules="passwordRules"
              color="primary"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
            <v-text-field
              v-model="password_confirmation"
              :type="showPasswordConfirmation ? 'text' : 'password'"
              :label="$t('customer_app.welcome.passwordConf')"
              :rules="[validPasswordConf]"
              required
              class="mx-md-4"
              color="primary"
              :append-icon="showPasswordConfirmation ? 'visibility' : 'visibility_off'"
              @click:append="showPasswordConfirmation = !showPasswordConfirmation"
            >
            </v-text-field>
            <v-btn class="mt-5 text-center" @click="resetPassword()" :disabled="!valid" color="primary">
              {{ $t('customer_app.resetPassword.save') }}
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventBus from '@/packs/event-bus'
import AuthService from '@/services/AuthService'

export default {
  data() {
    return {
      valid: true,
      customer: {},
      token: '',
      showPassword: false,
      showPasswordConfirmation: false,
      password: '',
      password_confirmation: '',
      passwordRules: [
        v => !!v || 'Le mot de passe est obligatoire',
        v => v.length > 7 || 'Le mot de passe doit contenir au moins 8 caractères'
      ]
    }
  },
  methods: {
    validPasswordConf(v) {
      return v === this.password ? true : 'Le mot de passe de confirmation est différent du mot de passe'
    },
    async resetPassword() {
      try {
        await AuthService.resetPassword(this.token, {
          customer: { password: this.password, password_confirmation: this.password_confirmation }
        })
        this.$router.push(`/${this.$route.params.id}`).catch(err => err)
        EventBus.$emit('showSnackbar', { text: 'Votre mot de passe à été modifié avec succès', color: 'success' })
      } catch (err) {
        EventBus.$emit('showSnackbar', { text: err, color: 'error' })
      }
    }
  },
  created() {
    if (this.$route.params.token) this.token = this.$route.params.token
  }
}
</script>
