<template>
  <div>
    <TransactionsListFilter class="mb-4" @filters="updateFilters($event)" v-if="dFilters" />

    <v-card v-if="transactions.length > 0" ref="transactions">
      <template v-for="(transaction, index) in transactions">
        <div v-if="index === 0 || headers.includes(index)" class="px-3 py-2 primary" :key="`header-date-${index}`">
          <label class="font-weight-medium text--white">
            {{ $moment(transaction.created_at).format('dddd Do MMMM YYYY') | capitalize }}
          </label>
        </div>
        <TransactionsListItem :transaction="transaction" :key="`item-${transaction.id}-${index}`" />
        <v-divider v-if="index + 1 < transactions.length" :key="`divider-${index}`"></v-divider>
      </template>
    </v-card>

    <div v-else class="flex-div justify-center mt-4">
      Aucune transaction disponible
    </div>

    <clip-loader class="my-5" :loading="loading" color="#78909c" width="60px" height="60px"></clip-loader>
  </div>
</template>

<script>
import TransactionsListFilter from '@/components/TransactionsListFilter'
import TransactionsListItem from '@/components/TransactionsListItem'
import CustomerService from '@/services/CustomerService'

export default {
  components: {
    TransactionsListFilter,
    TransactionsListItem
  },
  props: {
    refContainer: {
      type: HTMLDivElement,
      required: true
    },
    perPage: {
      type: Number
    },
    dFilters: {
      type: Boolean,
      default: true
    },
    dPagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pagination: {
        perPage: this.perPage ? this.perPage : 10,
        page: 1
      },
      transactions: [],
      loading: true,
      nbTransactions: 0,
      bottom: false,
      filters: Array,
      headers: []
    }
  },
  methods: {
    updateFilters(data) {
      this.transactions = []
      this.pagination.page = 1
      this.filters = data
      this.fetchTransactions()
    },
    headerDate() {
      this.transactions.map((e, index) => {
        if (
          this.transactions[index - 1] &&
          this.$moment(this.transactions[index - 1].created_at).format('L') !== this.$moment(e.created_at).format('L')
        )
          this.headers.push(index)
      })
    },
    async fetchTransactions() {
      this.loading = true
      const res = await CustomerService.getTransactions(this.pagination.perPage, this.pagination.page, this.moreFilters)

      if (res.collection.length > 0) {
        res.collection.forEach(transaction => {
          if (transaction.transaction_wallets.length > 0) this.transactions.push(transaction)
        })
      }
      this.nbTransactions = res.count
      this.headerDate()
      this.loading = false
    }
  },
  computed: {
    moreFilters() {
      let range = ''

      if (this.filters.date && this.filters.date.start && this.filters.date.end) {
        const startAt = this.$moment(this.filters.date.start).format('YYYY-MM-DD')
        const endAt = this.$moment(this.filters.date.end).format('YYYY-MM-DD')
        range = `&by_created_at=${startAt},${endAt}`
      }
      const pos =
        this.filters.selector && this.filters.selector.id ? `&by_point_of_sale_id=${this.filters.selector.id}` : ''
      return range + pos
    }
  },
  created() {
    this.refContainer.addEventListener(
      'scroll',
      () => {
        this.bottom =
          Math.round(this.refContainer.scrollHeight - this.refContainer.scrollTop) === this.refContainer.clientHeight
      },
      true
    )
    this.fetchTransactions()
  },
  destroyed() {
    this.refContainer.removeEventListener('scroll', e => e, true)
  },
  watch: {
    bottom(bottom) {
      if (bottom && this.transactions.length + 1 !== this.nbTransactions && !this.loading) {
        this.pagination.page++
        this.fetchTransactions()
      }
    }
  }
}
</script>
