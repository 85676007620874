<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="580">
    <v-card class="pa-4 text-center">
      <v-btn icon class="close-icon-btn" @click.native="$emit('input')">
        <v-icon>close</v-icon>
      </v-btn>
      <div class="flex-div flex-column align-center py-3">
        <h2 class="text-center my-3">{{ $t('customer_app.setEmail.title') }}</h2>
        <p class="text-center subtitle-1 my-3">{{ $t('customer_app.setEmail.text') }}<br /></p>
        <v-form v-model="valid" @submit.prevent="sendEmail">
          <v-text-field
            v-model="email"
            :rules="loginRules"
            prepend-icon="email"
            name="login"
            label="Votre adresse email"
            type="email"
            required
            color="primary"
          ></v-text-field>
          <v-btn class="mt-4" :disabled="!valid" color="primary" type="submit">
            {{ $t('customer_app.setEmail.send') }}
          </v-btn>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/packs/event-bus'
import AuthService from '@/services/AuthService'

export default {
  data() {
    return {
      valid: true,
      email: '',
      loginRules: [
        v => !!v || `L'adresse email est requise`,
        v => /.+@.+\..+/.test(v) || `L'adresse email indiquée n'est pas valide`
      ],
      errorsMapping: {
        'Email is not a valid address': 'Votre adresse email est invalide et nous ne pouvons vous envoyer les instructions de récupération de votre mot de passe.',
        'Email not found': 'Votre adresse email est introuvable dans notre base client.'
      }
    }
  },
  props: {
    value: {
      type: Boolean
    },
    subdomain: {
      type: String
    }
  },
  methods: {
    async sendEmail() {
      try {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('pass_companies_reset_password')

        await AuthService.forgotPassword(this.subdomain, this.email, token)

        EventBus.$emit('showSnackbar', {
          text: 'Un email vous a été envoyé, vérifiez votre boite mail',
          color: 'success'
        })

        this.$emit('input')
      } catch (err) {
        let text = err.response.data.errors ? err.response.data.errors.join(',') : err.response.data.error
        if (text in this.errorsMapping) { text = this.errorsMapping[text] }
        EventBus.$emit('showSnackbar', { text: text, color: 'error' })
      }
    }
  }
}
</script>
