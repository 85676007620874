<template>
  <v-container class="pt-0 grid-list-lg container--fluid">
    <v-row class="justify-center">
      <v-col cols="12" sm="8" lg="6" class="mt-4">
        <div v-if="logoUrl" class="mb-4" style="max-width: 110px;">
          <img :src="logoUrl" width="100%" alt="logo" />
        </div>
        <v-card class="pa-4">
          <div class="ma-4">
            <h2 class="text-center mt-2 font-weight-regular">
              {{ $t('sign_in_with_identifier.title') }}
            </h2>
          </div>
          <div class="px-4 py-2">
            <v-row class="justify-center">
              <v-col cols="12" v-if="subdomain === 'moustaches'" class="d-flex justify-center my-3">
                <img src="@/assets/img/undraw_waiting.svg" alt="illustration confirmation" height="220" />
              </v-col>
              <v-col cols="12" v-else class="d-flex justify-center my-3">
                <img src="@/assets/img/undraw_waiting.svg" alt="illustration confirmation" height="220" />
              </v-col>
              <v-col cols="12">
                <h3 class="font-weight-light text-center">
                  {{ $t('sign_in_with_identifier.textField') }}
                </h3>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  :rules="[v => !!v || $t('sign_in_with_identifier.identifierRequired')]"
                  solo
                  class="mt-2"
                  :label="$t('sign_in_with_identifier.textFieldLabel')"
                  v-model="identifier"
                  v-mask="identifierMask"
                ></v-text-field>
                <p class="caption text-center grey--text text--darken-1">
                  Nous utilisons le service reCAPTCHA de Google Inc. pour protéger les entrées des formulaires de ce
                  site. Pour plus d'informations concernant la politique de confidentialité de Google, rendez-vous sur
                  <a href="https://policies.google.com/privacy?hl=fr">cette page</a>.
                </p>
              </v-col>
            </v-row>
          </div>
          <div class="my-2 pb-4 justify-center flex-div">
            <v-btn fab color="primary" @click="sign_in()" :disabled="!identifier">
              <v-icon class="my-auto">arrow_forward</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventBus from '@/packs/event-bus'
import OwnerService from '@/services/OwnerService'
import CompanyService from '@/services/CompanyService'

export default {
  data() {
    return {
      identifier: null,
      identifierMask: '#### #### #### #### ####',
      subdomain: null,
      logoUrl: null
    }
  },
  methods: {
    async sign_in() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('pass_companies_sign_in')
      try {
        const res = await CompanyService.signInCompany(this.subdomain, {
          identifier: this.identifier.replace(/ /g, ''),
          'g-recaptcha-response': token
        })
        this.$router.push({ path: `/account/${this.subdomain}/create/new/${res.api_key}` }).catch(err => err)
      } catch (err) {
        EventBus.$emit('showSnackbar', { text: err.response.data.errors[0], color: 'error' })
      }
    },
    async fetchCompany() {
      try {
        const res = await OwnerService.get(this.subdomain)
        this.logoUrl = res.logo_url
      } catch (err) {
        this.$router.push('/404?errorType=subdomain').catch(err => err)
      }
    }
  },
  created() {
    if (this.$route.params.identifier) this.identifier = this.$route.params.identifier
    this.subdomain = this.$route.params.subdomain
    this.fetchCompany()
  }
}
</script>
