import RequestService from '@/services/RequestService'
import { store } from '@/store/store'

export default {
  async get(subdomain: string): Promise<void> {
    const res = await RequestService.getOwner(subdomain)
    store.dispatch('setOwner', res.data)
    return res.data
  },

  async getByKey(key: string): Promise<any> {
    const res = await RequestService.getOwnerByKey(key)
    store.dispatch('setOwner', res.data)
    return res.data
  }
}
