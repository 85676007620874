import RequestService from '@/services/RequestService'

export default {
  async getCompany(subdomain: string): Promise<any> {
    const res = await RequestService.getCompany(subdomain)
    return res.data
  },

  async getCompanySettings(subdomain: string): Promise<any> {
    const res = await RequestService.getCompanySettings(subdomain)
    return res.data
  },

  async signInCompany(subdomain: string, params: any): Promise<any> {
    const res = await RequestService.signInCompany(subdomain, params)
    return res.data
  },

  async getWelcomeCampaign(subdomain: string): Promise<any> {
    const res = await RequestService.getWelcomeCampaign(subdomain)
    return res.data
  },

  async getPointOfSales(): Promise<any> {
    const res = await RequestService.getPointOfSales()
    return res.data
  },

  async getPointOfSalesBySub(subdomain: string): Promise<any> {
    const res = await RequestService.getPointOfSalesBySub(subdomain)
    return res.data
  },

  async getCoreProgram(subdomain: string): Promise<any> {
    const res = await RequestService.getCoreProgram(subdomain)
    return res.data
  },

  async getDonationsCompany(id: string): Promise<any> {
    const res = await RequestService.getDonationsCompany(id)
    return res.data
  }
}
