import RequestService from '@/services/RequestService'
import { store } from '@/store/store'
import { clearToken } from '@/services/AxiosWrapper'

export default {
  async get(): Promise<any> {
    const res = await RequestService.getCustomer()
    if (!res.data.location) res.data.location = {}
    store.dispatch('setCustomer', res.data)

    return res.data
  },

  async getByKey(key: string): Promise<any> {
    const res = await RequestService.getCustomerByKey(key)
    if (!res.data.location) res.data.location = {}
    store.dispatch('setCustomer', res.data)

    return res.data
  },

  async deleteAccount(): Promise<void> {
    await RequestService.deleteAccount()
    store.dispatch('clearUser')
    clearToken()
  },

  async edit(params: any): Promise<any> {
    const res = await RequestService.editCustomer({ customer: params })
    return res.data
  },

  async editByKey(key: string, params: any): Promise<any> {
    const res = await RequestService.editCustomerByKey(key, { customer: params })
    return res.data
  },

  async editById(id: string, params: any): Promise<any> {
    const res = await RequestService.editCustomerById(id, { customer: params })
    return res.data
  },

  async editPassword(id: string, params: any): Promise<any> {
    const res = await RequestService.editCustomerPassword(id, { emailAndPassword: params })
    return res.data
  },

  async acceptTerms(): Promise<void> {
    await RequestService.acceptTerms()
  },

  async sendInformations(token: string, customer_key: string): Promise<void> {
    await RequestService.getInformations({ 'g-recaptcha-response': token, customer_key })
  },

  async getTransactions(perPage: number, page: number, options: string): Promise<any> {
    const res = await RequestService.getTransactions(perPage, page, options)
    return res.data
  },

  async getOffers(key: string): Promise<any> {
    const res = await RequestService.getOffers(key)
    return res.data
  },

  async getOfferById(offerId: string, id: string): Promise<any> {
    const res = await RequestService.getOfferById(offerId, id)
    return res.data
  },

  async getOffersPending(key: string): Promise<any> {
    const res = await RequestService.getOffersPending(key)
    return res.data
  },

  async activateOffer(offerId: string, id: string): Promise<void> {
    await RequestService.activateOffer(offerId, id)
  },

  async getDonationsCustomer(id: string, params: any): Promise<any> {
    const res = await RequestService.getDonationsCustomer(id, params)
    return res.data
  },

  async getDonationsVouchers(id: string): Promise<any> {
    const res = await RequestService.getDonationsVouchers(id)
    return res.data
  },

  async sendDonation(id: string, params: any): Promise<any> {
    const res = await RequestService.sendDonations(id, params)
    return res.data
  },

  async updateOptins(params: any): Promise<void> {
    const res = await RequestService.updateOptins(params)
    if (!res.data.location) res.data.location = {}
    store.dispatch('setCustomer', res.data)
  }
}
