<template>
  <v-dialog :value="true" @input="accessAccount()" persistent max-width="500">
    <v-card class="py-2 relative">
      <v-btn class="ml-auto close-btn flex-div" icon @click="accessAccount()">
        <v-icon>close</v-icon>
      </v-btn>
      <div class="flex-div flex-column align-center pa-4" v-if="customer">
        <h2 class="text-center font-weight-light my-3">
          {{ $t('customer_app.welcomeDialog.title') }}
        </h2>
        <img src="@/assets/img/undraw_confirmed.svg" alt="Email confirmé" class="mt-3 mb-4" width="40%" />
        <p class="text-center subtitle-1">{{ coreProgram.description }}</p>
        <v-btn @click="accessAccount()" color="primary">{{ $t('customer_app.confirmation.accessAccount') }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanyService from '@/services/CompanyService'

export default {
  data() {
    return {
      coreProgram: {}
    }
  },
  computed: {
    customer() {
      return this.$store.state.customer
    }
  },
  methods: {
    async fetchCoreProgram() {
      this.coreProgram = await CompanyService.getCoreProgram(this.customer.company.subdomain)
    },
    accessAccount() {
      this.$router.push({ name: 'wallet' }).catch(err => err)
    }
  },
  mounted() {
    this.fetchCoreProgram()
  }
}
</script>
