<template>
  <v-dialog :value="true" @input="$emit('input')" fullscreen hide-overlay transition="slide-x-reverse-transition">
    <v-card>
      <v-toolbar dark fixed color="primary">
        <v-btn icon @click="$router.push({ name: 'wallet' })" class="secondary--text">
          <v-icon>keyboard_arrow_left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" @click="saveData()" text class="secondary--text">
          <v-icon small class="mr-2">save</v-icon>
          {{ $t('customer_app.settings.save') }}
        </v-btn>
      </v-toolbar>
      <v-container class="grid-list-lg">
        <v-form ref="form" v-model="valid" lazy-validation class="flex-div justify-center">
          <v-row class="mt-2">
            <v-col cols="12">
              <h2 class="font-weight-regular">{{ $t('customer_app.welcome.formTitle') }}</h2>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="customer_.firstname"
                :rules="firstNameRules"
                :label="$t('customer_app.welcome.form.firstName')"
                required
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="customer_.lastname"
                :rules="lastNameRules"
                :label="$t('customer_app.welcome.form.lastName')"
                required
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <span class="d-block mt-1 caption grey--text text--lighten-1">Email</span>
              <div class="flex-div align-center">
                <span class="subtitle-1">{{ customer_.email }}</span>
                <v-btn class="ml-auto my-0" @click="displayEditEmail = true" icon small>
                  <v-icon small color="primary">edit</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <span class="d-block mt-1 mb-1 caption grey--text text--lighten-1">Mot de passe</span>
              <div class="flex-div align-center">
                <span class="subtitle-1">***********</span>
                <v-btn class="ml-auto my-0" @click="displayEditPass = true" icon small>
                  <v-icon small color="primary">edit</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" class="mt-2 py-0">
              <h2 class="font-weight-regular">{{ $t('customer_app.welcome.complementaryTitle') }}</h2>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="flex-div">
              <div class="flex-div align-end">
                {{ $t('customer_app.welcome.form.gender') }} :
                <v-radio-group v-model="customer_.gender" row hide-details class="mt-0 ml-5">
                  <v-radio :label="$t('gen.Mr')" value="male" color="primary"></v-radio>
                  <v-radio :label="$t('gen.Mrs')" value="female" color="primary"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="offset-md-1 py-0">
              <DatePicker
                :initial-value="customer_.birthdate"
                label="Date d'anniversaire"
                @update="customer_.birthdate = $event"
              />
            </v-col>
            <v-col cols="12">
              <h3>{{ $t('customer_app.welcome.form.location') }}</h3>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="customer_.location.line_1"
                :label="$t('customer_app.welcome.form.address1')"
                required
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="customer_.location.line_2"
                :label="$t('customer_app.welcome.form.address2')"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pr-2 py-0">
              <v-text-field
                v-model="customer_.location.zipcode"
                :label="$t('customer_app.welcome.form.zipcode')"
                required
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pl-2 py-0">
              <v-text-field
                v-model="customer_.location.city"
                :label="$t('customer_app.welcome.form.city')"
                required
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pr-2 py-0">
              <v-text-field
                prepend-icon="phone"
                v-model="customer.mobile"
                :label="$t('customer_app.welcome.form.phoneNumber')"
                required
                mask="## ## ## ## ##"
                color="primary"
              ></v-text-field>
            </v-col>
            <template v-if="customAttributes_">
              <v-col cols="12" class="mt-2">
                <h3 v-if="subdomain_ === 'moustaches'">{{ $t('customer_app.welcome.addPetMessage') }}</h3>
                <h3 v-else>Ajouter les membres de votre famille et soyez récompensé pour leur anniversaires.</h3>
              </v-col>
              <div class="flex-div flex-column w-100 pa-4">
                <div class="flex-div align-center">
                  <span v-if="subdomain_ === 'moustaches'" class="mr-3">{{ $t('customer_app.welcome.pets') }}</span>
                  <span v-else class="mr-3">{{ $t('customer_app.welcome.member') }}</span>
                  <v-radio-group @change="changeFamily()" v-model="local.family" row>
                    <v-radio :label="$t('gen.yesOpt')" :value="true" color="primary"></v-radio>
                    <v-radio :label="$t('gen.noOpt')" :value="false" color="primary"></v-radio>
                  </v-radio-group>
                </div>
                <div v-if="local.family" class="w-100">
                  <RelatedElements
                    :customerRelatedElements="customer_.related_elements"
                    :customAttributes="customAttributes_"
                    :owner="owner.company ? owner.company : owner"
                    @RelatedElementChange="relatedElementChange($event)"
                  />
                </div>
              </div>
            </template>
          </v-row>
        </v-form>
        <EditEmailDialog
          v-model="displayEditEmail"
          :customer="customer_"
          :subdomain="subdomain_"
          @emailChanged="emailChanged"
        />
        <EditPassDialog v-model="displayEditPass" :customer="customer_" :subdomain="subdomain_" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/packs/event-bus'
import EditEmailDialog from './EditEmailDialog'
import EditPassDialog from './EditPassDialog'
import RelatedElements from '@/components/RelatedElements'
import DatePicker from '@/components/DatePicker'
import CustomerService from '@/services/CustomerService'

export default {
  components: {
    EditEmailDialog,
    EditPassDialog,
    RelatedElements,
    DatePicker
  },
  data() {
    return {
      valid: true,
      local: {
        family: false
      },
      displayEditEmail: false,
      displayEditPass: false,
      menu: false,
      gender: [v => !!v || 'Le genre est requis'],
      firstNameRules: [v => !!v || 'Le prénom est requis'],
      lastNameRules: [v => !!v || 'Le nom est requis'],
      required: [v => !!v || 'Cette information est requise'],
      emailRules: [
        v => !!v || "L' e-mail est requis",
        v => /.+@.+\..+/.test(v) || "L'addresse e-mail doit être valide"
      ],
      passwordRules: [
        v => (v ? v.length > 7 && v.length < 1 : '' || 'Le mot de passe doit contenir au moins 8 caractères')
      ]
    }
  },
  computed: {
    customer_() {
      return this.customer
    },
    customAttributes_() {
      return this.owner.company ? this.owner.company.custom_attributes : this.owner.custom_attributes
    },
    subdomain_() {
      return this.owner.company ? this.owner.company.subdomain : this.owner.subdomain
    },
    emailAndPassword() {
      return {
        email: this.customer_.email,
        password: this.customer_.password,
        password_confirmation: this.customer_.password_confirmation
      }
    },
    changePassword() {
      return this.customer_.password && this.customer_.password_confirmation
    },
    owner() {
      return this.$store.state.owner
    },
    customer() {
      return this.$store.state.customer
    }
  },
  methods: {
    changeFamily() {
      if (this.local.family) this.customer_.related_elements.push({ custom_attributes: {} })
      else this.customer.related_elements = []
    },
    relatedElementChange(val) {
      Object.assign(this.customer_.related_elements, val)
    },
    emailChanged(val) {
      this.customer_.email = val
    },
    async saveData() {
      if (this.$refs.form.validate()) {
        let customer = JSON.parse(JSON.stringify(this.customer_))
        if (!customer.location.line_1 || !customer.location.zipcode) customer.location = null
        customer.point_of_sale_code = this.customer.point_of_sale.code

        const res = await CustomerService.editById(this.$route.params.id, customer)
        if (!res.location) res.location = { line_1: '', line_2: '', zipcode: '', city: '' }
        Object.assign(this.customer_, res)

        if (this.changePassword) await CustomerService.editPassword(this.$route.params.id, this.emailAndPassword)

        this.$emit('input')
        EventBus.$emit('showSnackbar', { text: 'Vos informations ont été mises à jour', color: 'success' })
      }
    },
    validPasswordConf(v) {
      if (this.customer_.password && !v) return 'Confirmez votre nouveau mot de passe'
      if (!this.customer_.password_confirmation || v !== this.customer_.password)
        return 'Le mot de passe de confirmation est différent du mot de passe'
      else return true
    },
    defaultLocation() {
      if (!this.customer_.location) this.customer_.location = { line_1: '', line_2: '', zipcode: '', city: '' }
    }
  },
  created() {
    this.defaultLocation()
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  }
}
</script>
