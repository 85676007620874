<template>
  <v-container class="grid-list-lg container--fluid">
    <v-row class="justify-center">
      <v-col cols="12" sm="10" lg="6" class="mt-3">
        <div v-if="owner.logo_url" class="mb-4" style="max-width: 110px;">
          <img :src="owner.logo_url" crossorigin="anonymous" width="100%" alt="logo" />
        </div>
        <v-card class="pa-4">
          <div class="ma-4" v-if="!hasDonate">
            <h2 class="text-center mt-2 font-weight-regular">
              {{ $t('donate.title') }}
            </h2>
          </div>
          <template v-if="!voucherPresence">
            <div class="px-4 py-2">
              <v-row class="mt-5 justify-center">
                <v-col cols="12" sm="6" class="flex-div flex-column align-center">
                  <h2 class="font-weight-light text-center">{{ $t('donate.voucherIdMessage') }}</h2>
                  <v-text-field
                    :rules="[v => !!v || $t('donate.voucherRequired')]"
                    solo
                    class="mt-4 w-100"
                    :label="$t('donate.labelVoucher')"
                    v-model="voucherID"
                    :mask="voucherMask"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <p class="text-center">
                    Nous utilisons le service reCAPTCHA de Google Inc. pour protéger les entrées des formulaires de ce
                    site. Pour plus d'informations concernant la politique de confidentialité de Google, rendez-vous sur
                    <a href="https://policies.google.com/privacy?hl=fr">cette page</a>.
                  </p>
                </v-col>
              </v-row>
            </div>
            <div class="my-2 pb-4 justify-center flex-div">
              <v-btn fab color="primary" @click="getVoucher()" :disabled="!voucherID">
                <v-icon class="my-auto">arrow_forward</v-icon>
              </v-btn>
            </div>
          </template>
          <div v-else>
            <template v-if="!hasDonate">
              <template v-if="loaded">
                <div class="px-4 py-2">
                  <v-row class="mt-5 justify-center">
                    <v-col cols="12" sm="10" class="flex-div align-center">
                      <h3 v-if="offer.remaining_amount">
                        {{ $t('donate.donateMessage') }}
                        <span class="font-weight-regular"> (max {{ offer.remaining_amount / 100 / 2 }} €) :</span>
                      </h3>
                      <v-slider
                        class="px-3"
                        v-model="donateAmount"
                        min="0"
                        :max="offer.remaining_amount / 100 / 2"
                        thumb-label="always"
                      ></v-slider>
                      <p>euro{{ donateAmount > 1 ? 's' : '' }}</p>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <span class="text-left">
                        {{ $t('donate.voucherBalance') }}:
                        {{ offer.remaining_amount / 100 - donateAmount }}
                        euro{{ offer.remaining_amount / 100 - donateAmount > 1 ? 's' : '' }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="justify-center">
                    <v-col cols="12" sm="10" md="9">
                      <v-card class="grey lighten-5 px-3 py-3 mb-3 mt-3 elevation-1">
                        <v-row class="align-center no-gutters">
                          <v-col cols="12" sm="3" v-if="donationLogoUrl">
                            <img :src="donationLogoUrl" width="100%" alt="lol" />
                          </v-col>
                          <v-col cols="12" sm="8" class="pr-0 pl-4">
                            <h3 class="d-inline font-weight-light">{{ $t('donate.messageStart') }}</h3>
                            <v-text-field
                              v-model="donateAmount"
                              min="1"
                              :max="offer.remaining_amount / 100"
                              hide-details
                              height="30px"
                              class="d-inline-block mt-0 mb-2 pt-0 mr-1 text-number-short text-field-right"
                              type="number"
                            ></v-text-field>
                            <h3 class="d-inline font-weight-light ">
                              euro{{ donateAmount > 1 ? 's' : '' }}
                              {{ $t('donate.to') }}
                              <b>Chiens Guides Paris.</b>
                            </h3>
                            <h3
                              v-if="currentCustomer.firstname && currentCustomer.lastname"
                              class="mt-3 font-weight-light text-xs-right"
                            >
                              {{ currentCustomer.firstname }} {{ currentCustomer.lastname }}
                            </h3>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <v-card-actions class="my-2 pb-4">
                  <v-btn @click="voucherPresence = false" color="error" class="ml-auto">
                    <v-icon small class="mr-2">cancel</v-icon>
                    {{ $t('button.cancel') }}
                  </v-btn>
                  <v-btn color="primary" class="mr-auto" @click="donate()" :disabled="donateAmount === 0">
                    <v-icon small class="mr-2">arrow_forward</v-icon>
                    {{ $t('button.donate') }}
                  </v-btn>
                </v-card-actions>
              </template>
              <clip-loader
                v-else
                class="my-4"
                :loading="!loaded"
                color="#78909c"
                width="60px"
                height="60px"
              ></clip-loader>
            </template>
            <div v-else-if="hasDonate" class="relative">
              <div class="flex-div justify-center">
                <v-row class="align-center no-gutters px-3 py-3">
                  <v-col cols="1">
                    <img src="@/assets/img/donation.svg" alt="logo donation" width="100%" />
                  </v-col>
                  <v-col cols="10" class="offset-xs1">
                    <p class="subtitle-1">
                      {{ $t('donate.validDonateMessageStart') }}<b>{{ donateAmount }} euros</b>.
                      <span>{{ $t('donate.validDonateMessageEnd') }}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-row class="no-gutters">
                  <v-col cols="12" sm="6" class="flex-div justify-center">
                    <v-btn color="primary" light @click="myAccount()">
                      <v-icon small class="mr-2">person</v-icon>
                      {{ $t('button.myAccount') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" class="flex-div justify-center">
                    <v-btn color="primary" light @click="downloadPDF()">
                      <v-icon small class="mr-2">picture_as_pdf</v-icon>
                      {{ $t('button.downloadVoucher') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import EventBus from '@/packs/event-bus'

import CompanyService from '@/services/CompanyService'
import CustomerService from '@/services/CustomerService'

export default {
  data() {
    return {
      loaded: false,
      currentCustomer: {},
      voucherPresence: false,
      voucherID: '',
      voucherMask: '#### #### #### #### #### #### ####',
      offer: {},
      newVoucher: {},
      donateAmount: 1,
      hasDonate: false,
      donationLogoUrl: ''
    }
  },
  computed: {
    owner() {
      return this.$store.state.owner.company ? this.$store.state.owner.company : this.$store.state.owner
    }
  },
  methods: {
    async getVoucher() {
      this.loaded = false
      this.voucherID = this.voucherID.replace(/\s/gi, '')

      if (this.voucherID.length > 0) {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('sign_in_by_voucher')

        try {
          await CustomerService.getDonationsCustomer(this.voucherID, { 'g-recaptcha-response': token })
            .then(async res => {
              this.currentCustomer = res
              await CustomerService.getDonationsVouchers(this.voucherID)
                .then(res => {
                  this.offer = res
                  this.voucherPresence = true
                  this.loaded = true
                })
                .catch(() => {
                  EventBus.$emit('showSnackbar', {
                    text: 'Le code du chèque est invalide',
                    color: 'error'
                  })
                  this.voucherPresence = false
                })
            })
            .catch(() => {
              EventBus.$emit('showSnackbar', {
                text: 'Le code du chèque est invalide',
                color: 'error'
              })
            })
        } catch (e) {
          EventBus.$emit('showSnackbar', {
            text: 'Le code du chèque est invalide',
            color: 'error'
          })
        }
      }
    },
    async donate() {
      const res = await CustomerService.sendDonation(this.voucherID, {
        donated_amount: this.donateAmount.unmonetized()
      })
      this.newVoucher = res.pdf_voucher_url
      this.offer = res
      this.hasDonate = true
    },
    downloadPDF() {
      axios({ url: this.newVoucher, method: 'GET', responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'voucher.pdf')
        document.body.appendChild(link)
        link.click()
      })
    },
    myAccount() {
      this.$router.push({ path: '/' + this.currentCustomer.api_key }).catch(err => err)
    }
  },
  async created() {
    if (this.$route.params.voucher) {
      this.voucherID = this.$route.params.voucher
      this.voucherPresence = true
      await this.getVoucher()

      try {
        const res = await CompanyService.getDonationsCompany(this.voucherID)
        this.donationLogoUrl = res.logo_url
      } catch (e) {
        EventBus.$emit('showSnackbar', {
          text: 'Le code du chèque est invalide',
          color: 'error'
        })
      }
    }
  }
}
</script>
