<template>
  <v-dialog :value="true" @input="$router.push({ name: 'wallet' })" fullscreen hide-overlay persistent>
    <v-card class="py-2 flex-div align-center justify-center">
      <div class="flex-div flex-column align-center pa-4">
        <h2 class="text-center mt-0">{{ $t('customer_app.confirmation.title') }}</h2>
        <h4 class="text-center subtitle-1">
          Votre inscription est maintenant complète,<br />vous allez maintenant recevoir un e-mail de bienvenue.
        </h4>
        <v-icon class="green--text mt-3 mb-4" size="100px">mark_email_read</v-icon>
        <p v-if="customer" class="text-center">
          {{ $t('customer_app.confirmation.text') }}<br />
          <i>{{ customer.email }}</i>
        </p>
        <v-btn @click="accessAccount()" color="primary" class="secondary--text">
          {{ $t('customer_app.confirmation.accessAccount') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    customer() {
      return this.$store.state.customer
    }
  },
  methods: {
    accessAccount() {
      this.$router.push({ path: `/${this.$route.params.id}` }).catch(err => err)
    }
  }
}
</script>
