<template>
  <div class="flex-div justify-center align-center w-100 h-100 pa-3 primary">
    <div v-if="customer">
      <img :src="owner.logo_url" :alt="owner.name" class="pa-3" style="height: 100px;" />
      <v-card elevation="1" max-width="500px" class="w-100 mt-2 pa-3">
        <h4>Abonnement à nos lettres d’information et à nos offres de remise exclusives</h4>
        <p class="mt-1 body-2">Cocher les options qui vous conviennent.</p>
        <v-checkbox v-model="customer.accept_mail_contact" :label="$t('unsubscribe.acceptMail')" hide-details />
        <v-checkbox v-model="customer.accept_postal_contact" :label="$t('unsubscribe.acceptPostMail')" hide-details />
        <v-checkbox v-model="customer.accept_sms_contact" :label="$t('unsubscribe.acceptSMS')" hide-details />
        <v-btn dark small color="primary" class="mx-0 mt-4" @click="saveOptins()">
          <v-icon small left>save</v-icon> Sauvegarder
        </v-btn>
        <v-divider class="my-5"></v-divider>
        <h4>Désactiver votre compte</h4>
        <p class="mb-3 body-2">
          Nous sommes désolés d'apprendre que vous nous quittez.
        </p>
        <v-btn dark small color="red" class="mx-0" @click="verification()">
          <v-icon small left>delete</v-icon> Désactiver mon compte
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import CustomerService from '@/services/CustomerService'
import AuthService from '@/services/AuthService'
import EventBus from '@/packs/event-bus'

export default {
  data() {
    return {
      customer: null
    }
  },
  computed: {
    owner() {
      return this.$store.state.owner.company ? this.$store.state.owner.company : this.$store.state.owner
    }
  },
  methods: {
    async saveOptins() {
      await CustomerService.updateOptins({
        customer: {
          accept_mail_contact: this.customer.accept_mail_contact,
          accept_sms_contact: this.customer.accept_sms_contact,
          accept_postal_contact: this.customer.accept_postal_contact
        }
      })
      EventBus.$emit('showSnackbar', { text: 'Vos paramètres de contacts ont été mise à jour', color: 'success' })
    },
    async verification() {
      this.$swal
        .fire({
          title: 'Êtes vous sûr ?',
          text: `Vous ne pourrez pas revenir en arrière`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: 'red',
          cancelButtonText: 'Annuler',
          confirmButtonColor: 'green',
          confirmButtonText: 'Désactiver'
        })
        .then(async result => {
          if (result.isConfirmed) {
            await CustomerService.deleteAccount()
            await this.$swal.fire('Votre compte a bien été désactivé', '', 'success').then(() => {
              this.$router.push(`/account/${this.owner.subdomain}`).catch(err => err)
            })
          }
        })
    }
  },
  async created() {
    if (sessionStorage.getItem('apiKey') !== this.$route.params.id) await AuthService.logout()
    sessionStorage.setItem('apiKey', this.$route.params.id)

    if (!this.$store.state.customer) await CustomerService.get()
    this.customer = this.$store.state.customer
  }
}
</script>
