<template>
  <div v-if="$browserDetect.isIOS && showPopup && !isInStandaloneMode">
    <div v-if="isSafari" class="popup arrow-bottom">
      <div class="popup-close elevation-3">
        <v-icon class="icon-close" @click="closePopup()">close</v-icon>
      </div>
      <div class="popup-wrapper pa-2">
        <div class="flex-div">
          <div class="flex-div align-center pr-2">
            <v-icon style="font-size: 35px; color: #0000008a">add_box</v-icon>
          </div>
          <div class="body-2">
            Pour ajouter cette eCarte sur votre iPhone : <span class="font-weight-bold mr-1">Appuyez sur</span>
            <img src="@/assets/img/share_ios.png" alt="share button ios" />
            puis <span class="font-weight-bold">Ajouter à l'écran d'accueil</span>.
          </div>
        </div>
      </div>
    </div>
    <div v-else class="popup remove-arrow">
      <div class="popup-close elevation-3">
        <v-icon class="icon-close" @click="closePopup()">close</v-icon>
      </div>
      <div class="popup-wrapper pa-2">
        <div class="flex-div">
          <div class="flex-div align-center pr-2">
            <v-icon style="font-size: 35px; color: #0000008a">info</v-icon>
          </div>
          <div class="body-2">
            Pour ajouter cette eCarte sur votre iPhone : <span class="font-weight-bold">Ouvrez ce lien</span>
            sur Safari car votre navigateur ne prend pas en charge cette fonctionnalitée.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: !localStorage.getItem('show_popup')
    }
  },
  computed: {
    isSafari() {
      return ((this.$browserDetect.isSafari || this.$browserDetect.meta.name === 'Safari') && !this.$browserDetect.isChromeIOS && !this.$browserDetect.isChrome)
    },
    isInStandaloneMode() {
      return ('standalone' in window.navigator) && (window.navigator.standalone)
    }
  },
  methods: {
    closePopup() {
      localStorage.setItem('show_popup', false)
      this.showPopup = false
    }
  }
}
</script>

<style lang="css">
.popup-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: -25px;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
}

.icon-close {
  font-size: 15px !important;
  color: black !important;
}

.popup {
  width: 100vw;
  position: fixed;
  bottom: 25px;
  z-index: 999;
  max-width: fit-content;
  left: 50%;
  transform: translate(-50%, 0);
}

.popup img {
  width: 20px;
  margin-bottom: -1px;
}

.popup:after {
  content: '';
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
}

.popup:before {
  content: '';
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: absolute;
  z-index: 998;
}

.popup.arrow-bottom:before {
  left: calc(50% - 10px);
  bottom: -8px;
}

.popup.remove-arrow:before {
  display: none;
}

.popup-wrapper {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  position: relative;
  z-index: 998;
}
</style>
