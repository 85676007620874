<template>
  <div class="primary h-100">
    <v-container class="grid-list-lg">
      <v-card v-if="loaded" class="px-8 py-6 ml-auto mr-auto elevation-3 rounded" max-width="800px">
        <div class="flex-div justify-center" style="align-items: center">
          <div v-if="owner.logo_url" class="flex-div align-center">
            <img :src="owner.logo_url" class="owner-logo elevation-3" />
          </div>
          <div class="ml-5">
            <h2>{{ owner.name }}</h2>
            <h3 v-if="!$route.params.apikey" class="font-weight-light">
              {{ $t('customer_app.welcome.titleStartCreate') }} <b>{{ owner.name }}</b>
              {{ $t('customer_app.welcome.titleEndCreate') }}
            </h3>
            <h3 v-else class="font-weight-light">
              {{ $t('customer_app.welcome.title') }}<b>{{ owner.name }}</b>.
            </h3>
          </div>
        </div>
        <v-divider class="my-4"></v-divider>
        <v-row class="px-3" v-if="pointOfSalesList.length > 0">
          <v-col cols="12">
            <h4 class="body-2 font-weight-bold mb-2">* {{ $t('customer_app.welcome.pos') }}</h4>
            <v-select
              solo
              v-model="customer.point_of_sale_code"
              required
              :items="pointOfSalesList"
              item-text="name"
              item-value="code"
              :error-messages="posCodeErrors"
              prepend-inner-icon="store"
              color="primary"
              menu-props="offsetY"
              label="Liste des points de vente"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-form ref="form" class="px-3">
          <v-row>
            <v-col cols="12">
              <h4 class="subtitle-1 font-weight-bold">
                {{ $t('customer_app.welcome.formTitle') }}
                <span class="font-weight-medium body-1">
                  (<span class="red--text mr-1">*</span> Champs obligatoires)
                </span>
              </h4>
            </v-col>
            <v-col cols="12" class="my-2" v-if="messageWelcomeBasic()">
              <v-alert
                :value="true"
                color="grey lighten-3"
                light
                class="flex-div align-center grey--text text--darken-4"
              >
                <v-row>
                  <v-col class="shrink">
                    <img src="@/assets/img/reward-input.png" alt="reward" width="30px" />
                  </v-col>
                  <v-col class="flex-div align-center">
                    <span class="px-2"> {{ messageWelcomeBasic() }}.</span>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="customer.firstname"
                :label="$t('customer_app.welcome.form.firstName')"
                :error-messages="firstnameErrors"
                @input="$v.customer.firstname.$touch()"
                @blur="$v.customer.firstname.$touch()"
                required
                color="primary"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="customer.lastname"
                :label="$t('customer_app.welcome.form.lastName')"
                :error-messages="lastnameErrors"
                @input="$v.customer.lastname.$touch()"
                @blur="$v.customer.lastname.$touch()"
                required
                color="primary"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="customer.email"
                :label="$t('customer_app.welcome.form.email')"
                :error-messages="emailErrors"
                @input="$v.customer.email.$touch()"
                @blur="$v.customer.email.$touch()"
                required
                color="primary"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="password"
                type="password"
                :label="$t('customer_app.welcome.password')"
                :error-messages="passwordErrors"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                required
                color="primary"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="password_confirmation"
                type="password"
                :label="$t('customer_app.welcome.passwordConf')"
                :error-messages="passwordConfirmationErrors"
                @input="$v.password_confirmation.$touch()"
                @blur="$v.password_confirmation.$touch()"
                required
                color="primary"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <h4 class="subtitle-1 font-weight-bold">
                {{ $t('customer_app.welcome.complementaryTitle') }}
              </h4>
            </v-col>

            <v-col cols="12" class="my-2" v-if="messageWelcomeFull()">
              <v-alert
                :value="true"
                color="grey lighten-3"
                light
                class="flex-div align-center grey--text text--darken-4"
              >
                <v-row>
                  <v-col class="shrink">
                    <img src="@/assets/img/reward-input.png" alt="reward" width="30px" />
                  </v-col>
                  <v-col class="flex-div align-center">
                    <span class="px-2"> {{ messageWelcomeFull() }}.</span>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>

            <v-col cols="12" md="6" class="flex-div align-center">
              {{ $t('customer_app.welcome.form.gender') }}:
              <v-radio-group
                :class="{ 'reward-input': displayWelcomeFull && displayWelcomeBadge('gender') }"
                v-model="customer.gender"
                row
                hide-details
                class="mt-0 ml-3"
              >
                <v-radio :label="$t('gen.Mr')" value="male" color="primary"></v-radio>
                <v-radio :label="$t('gen.Mrs')" value="female" color="primary"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6">
              <DatePicker
                :initialValue="customer.birthdate"
                label="Date d'anniversaire"
                :displayWelcomeBadge="displayWelcomeFull && displayWelcomeBadge('birthdate')"
                @update="customer.birthdate = $event"
              />
            </v-col>

            <v-col cols="12">
              <h4 class="body-2 font-weight-bold mb-2">{{ $t('customer_app.welcome.form.location') }}</h4>
            </v-col>

            <template>
              <v-col cols="12" md="6">
                <v-text-field
                  class="pt-0 mt-0"
                  :class="{
                    'reward-input': displayWelcomeFull && displayWelcomeBadge('location.line_1')
                  }"
                  v-model="location.line_1"
                  :label="$t('customer_app.welcome.form.address1')"
                  required
                  color="primary"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  class="pt-0 mt-0"
                  :class="{
                    'reward-input': displayWelcomeFull && displayWelcomeBadge('location.line_2')
                  }"
                  v-model="location.line_2"
                  :label="$t('customer_app.welcome.form.address2')"
                  color="primary"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  :class="{
                    'reward-input': displayWelcomeFull && displayWelcomeBadge('location.zipcode')
                  }"
                  v-model="location.zipcode"
                  :label="$t('customer_app.welcome.form.zipcode')"
                  required
                  color="primary"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :class="{
                    'reward-input': displayWelcomeFull && displayWelcomeBadge('location.city')
                  }"
                  v-model="location.city"
                  :label="$t('customer_app.welcome.form.city')"
                  required
                  color="primary"
                >
                </v-text-field>
              </v-col>
            </template>
            <v-col cols="12" md="5">
              <v-text-field
                prepend-icon="phone"
                v-model="customer.mobile"
                :class="{
                  'reward-input': displayWelcomeFull && displayWelcomeBadge('phone')
                }"
                :label="$t('customer_app.welcome.form.phoneNumber')"
                mask="## ## ## ## ##"
                required
                color="primary"
              >
              </v-text-field>
            </v-col>
            <template v-if="this.owner && this.owner.custom_attributes && this.owner.custom_attributes.length">
              <v-col cols="12">
                <h4 class="body-2 font-weight-bold mb-2" v-if="owner.subdomain.includes('moustaches')">
                  {{ $t('customer_app.welcome.addPetMessage') }}
                </h4>
                <h4 class="body-2 font-weight-bold mb-2" v-else>
                  Ajouter les membres de votre famille pour pouvoir personnaliser vos récompenses
                </h4>
              </v-col>
              <v-col cols="12" class="flex-div flex-column">
                <div class="flex-div">
                  <span v-if="owner.subdomain.includes('moustaches')" class="mr-3 flex-div mt-1">
                    {{ $t('customer_app.welcome.pets') }}
                    <img
                      v-if="displayWelcomeFull && displayWelcomeBadge('related_elements')"
                      src="@/assets/img/reward-input.png"
                      class="align-self-start ml-1"
                      alt="reward"
                      width="20px"
                    />
                  </span>
                  <span v-else class="mr-3 flex-div mt-1">
                    {{ $t('customer_app.welcome.member') }}
                    <img
                      v-if="displayWelcomeFull && displayWelcomeBadge('related_elements')"
                      src="@/assets/img/reward-input.png"
                      class="align-self-start ml-1"
                      alt="reward"
                      width="20px"
                    />
                  </span>

                  <v-radio-group @change="changeFamily()" v-model="local.family" row class="pt-0 mt-0">
                    <v-radio :label="$t('gen.yesOpt')" :value="true" color="primary"></v-radio>
                    <v-radio :label="$t('gen.noOpt')" :value="false" color="primary"></v-radio>
                  </v-radio-group>
                </div>

                <div v-if="local.family" class="w-100">
                  <RelatedElements
                    :customerRelatedElements="customer.related_elements"
                    :customAttributes="owner.custom_attributes"
                    :owner="owner"
                    @RelatedElementChange="RelatedElementChange"
                  />
                </div>
              </v-col>
            </template>
          </v-row>
        </v-form>

        <v-divider class="mt-2 mb-4"></v-divider>

        <v-list>
          <v-list-item class="accept-cgu-checkbox">
            <v-list-item-action>
              <v-checkbox
                color="primary"
                v-model="checkCGU"
                :error-messages="checkCGUErrors"
                @change="$v.checkCGU.$touch()"
                @blur="$v.checkCGU.$touch()"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content @click="checkCGU = !checkCGU">
              <span class="font-italic body-2 font-weight-light">
                {{ $t('customer_app.welcome.cguLabelStart') }}
                <a class="link" @click.prevent="$router.push({ name: 'conditionsWelcome' })">
                  {{ $t('customer_app.welcome.cguLink') }}
                </a>
                {{ $t('customer_app.welcome.cguLabelEnd') }}.
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="flex-div justify-center">
          <v-btn class="secondary--text btn-registration w-50" color="primary" @click="submit()">
            <v-icon class="mr-2" small>save</v-icon>
            {{ $t('customer_app.welcome.registration') }}
          </v-btn>
        </div>
      </v-card>
      <v-dialog :value="registerLoading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Veuillez patienter ...
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <router-view />
    </v-container>
  </div>
</template>

<script>
import EventBus from '@/packs/event-bus'
import { required, sameAs, minLength, email } from 'vuelidate/lib/validators'
import RelatedElements from '@/components/RelatedElements'
import DatePicker from '@/components/DatePicker'
import AuthService from '@/services/AuthService'
import CompanyService from '@/services/CompanyService'
import CustomerService from '@/services/CustomerService'
import OwnerService from '@/services/OwnerService'

export default {
  components: {
    RelatedElements,
    DatePicker
  },
  data() {
    return {
      welcomeCampaign: null,
      checkCGU: false,
      local: {
        family: false
      },
      customer: {
        point_of_sale_code: '',
        birthdate: null,
        related_elements: []
      },
      location: {},
      password: '',
      password_confirmation: '',
      loaded: false,
      registerLoading: false,
      pointOfSalesList: []
    }
  },
  validations: {
    customer: {
      firstname: { required },
      lastname: { required },
      email: { required, email },
      point_of_sale_code: { required }
    },

    password: { required, minLength: minLength(8) },
    password_confirmation: { required, sameAsPassword: sameAs('password') },
    checkCGU: {
      checked(val) {
        return val
      }
    }
  },
  computed: {
    owner() {
      return this.$store.state.owner
    },
    firstnameErrors() {
      const errors = []
      if (!this.$v.customer.firstname.$dirty) return errors
      !this.$v.customer.firstname.required && errors.push('Le prénom est requis')
      return errors
    },
    lastnameErrors() {
      const errors = []
      if (!this.$v.customer.lastname.$dirty) return errors
      !this.$v.customer.lastname.required && errors.push('Le nom est requis')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.customer.email.$dirty) return errors
      !this.$v.customer.email.required && errors.push("L'email est requis")
      !this.$v.customer.email.email && errors.push("L'email n'est pas valide")
      return errors
    },
    posCodeErrors() {
      const errors = []
      if (!this.$v.customer.point_of_sale_code.$dirty) return errors
      !this.$v.customer.point_of_sale_code.required && errors.push('Le point de vente est requis')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Le mot de passe est requis')
      !this.$v.password.minLength &&
        errors.push(`Le mot de passe doit contenir au moins ${this.$v.password.$params.minLength.min} charactères`)
      return errors
    },
    passwordConfirmationErrors() {
      const errors = []
      if (!this.$v.password_confirmation.$dirty) return errors
      !this.$v.password_confirmation.required && errors.push('Le mot de passe de confirmation est requis')
      !this.$v.password_confirmation.sameAsPassword && errors.push('Les mots de passe doivent être identique')
      return errors
    },
    checkCGUErrors() {
      const errors = []
      if (!this.$v.checkCGU.$dirty) return errors
      !this.$v.checkCGU.checked && errors.push("Vous n'avez pas accepté les CGU")
      return errors
    },
    notFullInformations() {
      return !(this.location && this.location.line_1 && this.location.zipcode && this.location.city)
    },
    displayWelcomeFull() {
      return this.messageWelcomeFull() || (this.messageWelcomeFull() && this.notFullInformations)
    },
    birthdate() {
      return this.customer.birthdate ? this.customer.birthdate.split(/[ ,]+/)[0] : ''
    }
  },
  methods: {
    messageWelcomeBasic() {
      if (this.welcomeCampaign && this.welcomeCampaign.awards) {
        let awardBasic = this.welcomeCampaign.awards.filter(award => award.triggers[0].threshold === 0)
        return awardBasic[0] ? awardBasic[0].messages.fr : false
      } else return false
    },
    messageWelcomeFull() {
      if (this.welcomeCampaign && this.welcomeCampaign.awards) {
        const awardFull = this.welcomeCampaign.awards.filter(award => award.triggers[0].threshold === 1)
        return awardFull[0] ? awardFull[0].messages.fr : false
      } else return false
    },
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.customer.first_visit) this.customer.accept_mail_contact = true
        this.$route.params.apikey ? this.editCustomer() : this.createCustomer()
      } else {
        EventBus.$emit('showSnackbar', {
          text: "Le formulaire d'inscription contient des erreurs, veuillez vérifier vos informations",
          color: 'error'
        })
      }
    },
    displayWelcomeBadge(value) {
      return this.owner.full_registration_fields.includes(value)
    },
    async editCustomer() {
      this.registerLoading = true
      const subdomain = this.$route.params.subdomain
      this.checkLocation()

      try {
        if (this.customer.first_visit) {
          await CustomerService.editByKey(this.$route.params.apikey, {
            email: this.customer.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            subdomain
          })
        }
        await AuthService.login(this.customer.email, this.password, subdomain)
        this.customer = await CustomerService.edit(this.customer)
        await CustomerService.acceptTerms()

        this.$router
          .push(
            this.customer.confirmed
              ? `/${this.$route.params.apikey}/welcome`
              : `/account/${subdomain}/confirm/${this.$route.params.apikey}`
          )
          .catch(err => err)
      } catch (err) {
        this.registerLoading = false
        if (!err.response) EventBus.$emit('showSnackbar', { text: "Une erreur s'est produite", color: 'error' })
        else if (err.response.status === 422) EventBus.$emit('showSnackbar', { text: this.$t('customer_app.welcome.emailError'), color: 'error' })
        else EventBus.$emit('showSnackbar', { text: typeof (err.response.data.errors) === Array ? err.response.data.errors.join(',') : err.response.data.errors, color: 'error' })
      }
      this.registerLoading = false
    },
    async createCustomer() {
      try {
        this.registerLoading = true
        const subdomain = this.$route.params.subdomain
        this.customer.password = this.password
        this.customer.password_confirmation = this.password_confirmation
        this.checkLocation()

        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('pass_companies_register')

        await AuthService.register(subdomain, this.customer, token)
        await AuthService.login(this.customer.email, this.password, subdomain)
        await CustomerService.acceptTerms()

        this.$router.push(`/account/${subdomain}/confirm/${this.$route.params.apikey}`).catch(err => err)
      } catch (err) {
        if (!err.response) EventBus.$emit('showSnackbar', { text: "Une erreur s'est produite", color: 'error' })
        else if (err.response.status === 422) {
          if (err.response.data.errors[0] === 'Email has already been taken')
            EventBus.$emit('showSnackbar', { text: this.$t('customer_app.welcome.emailError'), color: 'error' })
          else if (err.response.data.errors[0] === 'A confirmation email has already been sent. Please check your inbox.')
            EventBus.$emit('showSnackbar', { text: this.$t('customer_app.welcome.confirmationError'), color: 'error' })
          else if (err.response.data.errors[0] === 'You already have an account. Please register with your identifier.')
            EventBus.$emit('showSnackbar', { text: this.$t('customer_app.welcome.registerId'), color: 'error' })
        } else EventBus.$emit('showSnackbar', { text: typeof (err.response.data.errors) === Array ? err.response.data.errors.join(',') : err.response.data.errors, color: 'error' })
        this.registerLoading = false
      }
    },
    async fetchWelcomeCampaign() {
      const res = await CompanyService.getWelcomeCampaign(this.$route.params.subdomain)
      this.welcomeCampaign = res[0]
    },
    async fetchCustomer() {
      if (!this.$route.params.apikey) return

      const res = await CustomerService.getByKey(this.$route.params.apikey)
      if (res.accept_terms_of_use)
        this.$router
          .push(
            res.confirmed
              ? `/${this.$route.params.apikey}`
              : `/account/${this.$route.params.subdomain}/confirm/${this.$route.params.apikey}`
          )
          .catch(err => err)

      this.customer = res
      this.location = res.location

      if (this.customer.point_of_sale) this.customer.point_of_sale_code = this.customer.point_of_sale.code
      else this.customer.point_of_sale_code = ''
    },
    async fetchOwner() {
      try {
        await OwnerService.get(this.$route.params.subdomain)
      } catch (err) {
        this.$router.push('/404?errorType=subdomain').catch(err => err)
      }
      this.loaded = true
    },
    async fetchPointOfSales() {
      try {
        this.pointOfSalesList = await CompanyService.getPointOfSalesBySub(this.$route.params.subdomain)
      } catch (err) {
        this.$router.push('/404?errorType=subdomain').catch(err => err)
      }
    },
    checkLocation() {
      if (
        Object.keys(this.location).length === 0 ||
        (this.location.zipcode &&
          this.location.zipcode.length === 0 &&
          this.location.city &&
          this.location.city.length === 0)
      )
        this.customer.location = null
      else this.customer.location = this.location
    },
    RelatedElementChange(val) {
      Object.assign(this.customer.related_elements, val)
    },
    changeFamily() {
      if (this.local.family)
        this.customer.related_elements.push({ birthdate: this.$moment().format('YYYY-MM-DD'), custom_attributes: {} })
      else this.customer.related_elements = []
    }
  },
  created() {
    AuthService.logout()

    this.fetchWelcomeCampaign()
    this.fetchCustomer()
    this.fetchOwner()
    this.fetchPointOfSales()
  }
}
</script>
