<template>
  <v-dialog :value="true" @input="$router.push({ name: 'wallet' })" fullscreen hide-overlay transition="slide-x-reverse-transition">
    <v-card>
      <v-toolbar fixed color="primary">
        <v-btn icon @click="$router.push({ name: 'wallet' })" class="secondary--text">
          <v-icon>keyboard_arrow_left</v-icon>
        </v-btn>
        <v-toolbar-title class="secondary--text">{{ $t('customer_app.settings.title') }} </v-toolbar-title>
      </v-toolbar>
      <div class="pa-4">
        <h2 class="font-weight-regular">Notifications</h2>
        <v-checkbox @change="acceptNotification('accept_mail_contact')" v-model="customer.accept_mail_contact" :label="$t('customer_app.settings.acceptMail')" color="primary" hide-details></v-checkbox>
        <v-checkbox @change="acceptNotification('accept_postal_contact')" v-model="customer.accept_postal_contact" :label="$t('customer_app.settings.acceptPostal')" color="primary" hide-details></v-checkbox>
      </div>
      <v-divider class="my-3"></v-divider>
      <div class="text-center">
        <v-btn depressed color="error" @click.stop="deleteDialog = true" class="mt-3">
          {{ $t('customer_app.settings.deactivate') }}
        </v-btn>
        <v-dialog v-model="deleteDialog" max-width="290">
          <v-card>
            <v-card-title class="subtitle-1">{{ $t('customer_app.settings.deleteConfirm') }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleteDialog = false">
                {{ $t('gen.noOpt') }}
              </v-btn>
              <v-btn color="primary" text @click="deleteAccount()">
                {{ $t('gen.yesOpt') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomerService from '@/services/CustomerService'

export default {
  data() {
    return {
      passwordChanged: false,
      newPassword: '',
      newPasswordConfirm: '',
      passwordRules: [v => !!v || 'Password is required', v => v.length > 7 || 'Password not long enough'],
      deleteDialog: false
    }
  },
  computed: {
    customer() {
      return this.$store.state.customer
    }
  },
  methods: {
    checkRulesPassword() {
      return !(this.newPassword && this.newPasswordConfirm && this.newPassword === this.newPasswordConfirm)
    },
    acceptNotification(type) {
      CustomerService.editByKey(this.$route.params.id, {
        [type]: type === 'accept_mail_contact' ? this.customer.accept_mail_contact : this.customer.accept_postal_contact
      })
    },
    deleteAccount() {
      CustomerService.deleteAccount()
    }
  }
}
</script>
