<template>
  <div v-if="loading" class="h-100">
    <v-alert v-model="showUnsubscribeAlert" @input="$router.replace({ query: null })" type="success" dismissible>
      Vous ne recevrez plus vos offres promotionnelles par e-mail.
    </v-alert>
    <AlertPWA />
    <v-container class="grid-list-lg overflow relative h-100" :class="{ 'pa-0': $vuetify.breakpoint.mdAndDown, 'pt-3': $vuetify.breakpoint.lgAndUp }">
      <div v-if="customer" class="hidden-sm-and-down my-5 flex-div align-center">
        <h3 class="font-weight-regular primary--text">
          {{ $t('customer_app.dashboard.messageStart') }}
          <b>{{ customer.firstname }} {{ customer.lastname }},</b>
          {{ $t('customer_app.dashboard.messageEnd') }}
        </h3>
        <v-btn v-if="user" class="ml-auto mr-0 secondary--text" color="primary" @click="logout()">
          <v-icon class="mr-2" :small="true">exit_to_app</v-icon>
          {{ $t('customer_app.dashboard.signOut') }}
        </v-btn>
      </div>
      <v-row>
        <v-col cols="12" sm="12" md="4" :class="{ 'pt-0': $vuetify.breakpoint.smAndDown }">
          <v-card class="primary pb-2">
            <div class="flex-div flex-column px-4 pt-3 pb-1 dark">
              <div class="py-0 justify-center w-100 flex-div my-2">
                <template v-if="owner">
                  <img v-if="owner.logo_url" :src="owner.logo_url" alt="logo" crossorigin="anonymous" class="owner-logo-dashboard" />
                  <span v-else class="font-weight-light w-100 secondary--text subtitle-1">
                    {{ owner.name | capitalize }}
                  </span>
                </template>
                <VueSkeletonLoader v-else rounded :height="'100px'" :width="'100px'" type="rect"></VueSkeletonLoader>
              </div>
              <div class="flex-div py-5">
                <span class="font-weight-bold w-100 title secondary--text d-block text-xs-left">
                  {{ customer.firstname }} {{ customer.lastname | uppercase }}
                </span>
                <span class="secondary--text subtitle-1 text-right w-100 d-block" v-if="customer.core_wallet">
                  <b class="title">{{ customer.core_wallet.balance }}</b>
                  {{ customer.core_wallet.loyalty_currency_units }}
                </span>
              </div>
            </div>
            <div class="illustration-fid flex-div">
              <template v-if="owner">
                <img v-if="owner.pass_banner_url" :src="owner.pass_banner_url" crossorigin="anonymous" alt="illustration fid" />
                <img v-else src="@/assets/img/ekoya.jpg" crossorigin="anonymous" alt="illutration fid" />
              </template>
              <VueSkeletonLoader v-else :heigth="'120px'" :width="'100%'"></VueSkeletonLoader>
            </div>
            <div class="pt-4 pb-2 px-4">
              <v-card class="flex-div align-center flex-column pt-0 white px-3 pt-2">
                <img v-if="customer" width="100%" :src="customer.identifiers[0].images.barcode_svg" crossorigin="anonymous" alt="barCode" />
                <VueSkeletonLoader v-else rounded :height="'86px'" :width="'100%'"></VueSkeletonLoader>
                <h3 v-if="customer.identifiers[0].formatted_identifier" class="text-center mb-2 font-weight-regular grey--text text--darken-4">
                  {{ customer.identifiers[0].formatted_identifier }}
                </h3>
              </v-card>
              <div v-if="$vuetify.breakpoint.mdAndUp" class="hidden-sm-and-down text-center mt-4 flex-div flex-column">
                <v-btn v-if="user" @click.prevent="$router.push({ name: 'settingsCustomer' })" text class="secondary--text">
                  <v-icon class="mr-2">settings</v-icon>Paramètres
                </v-btn>
                <v-btn @click="$router.push({ name: 'conditionsCustomer' })" text class="secondary--text mt-2">
                  <v-icon :small="true" class="mr-2">info</v-icon>
                  {{ $t('customer_app.dashboard.programInfo') }}
                </v-btn>
              </div>
            </div>
            <div class="px-4">
              <PendingOffers />
            </div>
          </v-card>
        </v-col>
        <!-- Desktop -->
        <v-col cols="12" sm="12" md="8">
          <v-row>
            <v-col cols="12" class="flex-div hidden-sm-and-down">
              <v-card>
                <v-row>
                  <v-col cols="12" lg="7" order-lg="1" order="2">
                    <SendEmail />
                  </v-col>
                  <v-col cols="12" lg="5" order-lg="2" order="1">
                    <div class="grey lighten-5 h-100 flex-div flex-column pa-4">
                      <p class="subtitle-1 text-center grey--text text--darken-3 mb-3">
                        <b>{{ customer.email }}</b>
                      </p>
                      <v-btn @click.prevent="$router.push({ name: 'editCustomer' })" text class="mx-0 grey--text text--darken-3">
                        <v-icon color="primary" :small="true" class="mr-2">edit</v-icon>
                        {{ $t('customer_app.dashboard.edit') }}
                      </v-btn>
                      <v-btn @click.prevent="$router.push({ name: 'historyCustomer' })" text class="mx-0 grey--text text--darken-3 mt-4">
                        <v-icon color="primary" :small="true" class="mr-2">history</v-icon>
                        {{ $t('customer_app.dashboard.history') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" class="px-5 px-sm-3">
              <Offers />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <MobileMenu :owner="owner" :customer="customer" />
      <router-view />
    </v-container>
    <PopupSafariIOS />
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'
import CustomerService from '@/services/CustomerService'

import PendingOffers from '@/components/PendingOffers'
import Offers from '@/views/Offers'

import PopupSafariIOS from '@/components/PopupSafariIOS'
import AlertPWA from '@/components/AlertPWA'
import SendEmail from '@/components/SendEmail'
import MobileMenu from '@/components/MobileMenu'

import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    AlertPWA,
    SendEmail,
    MobileMenu,
    Offers,
    PendingOffers,
    PopupSafariIOS,
    VueSkeletonLoader
  },
  data() {
    return {
      loading: false,
      showUnsubscribeAlert: false
    }
  },
  computed: {
    customer() {
      return this.$store.state.customer
    },
    owner() {
      return this.$store.state.owner.company ? this.$store.state.owner.company : this.$store.state.owner
    },
    user() {
      return this.$store.state.user
    }
  },
  created() {
    if (sessionStorage.getItem('apiKey') !== this.$route.params.id) AuthService.logout()
    sessionStorage.setItem('apiKey', this.$route.params.id)

    if (this.$route.query.alert === 'unsubscribe') this.showUnsubscribeAlert = true
    this.fetchCustomer()
  },
  methods: {
    async fetchCustomer() {
      try {
        const res = await CustomerService.get()
        if (!res.accept_terms_of_use)
          this.$router.push(`/account/${res.company.subdomain}/create/new/${this.$route.params.id}`).catch(err => err)
        else if (this.$route.params.confirm) this.$router.push({ name: this.$route.params.confirm }).catch(err => err)
        this.loading = true
      } catch (err) {
        this.$router.push('/404?errorType=apiKey').catch(err => err)
      }
    },
    logout() {
      AuthService.logout()
    }
  }
}
</script>
