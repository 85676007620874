<template>
  <div v-if="pendingOffers.length > 0 && !offersLoading">
    <v-alert value="true" color="white" class="py-2 overflow w-100">
      <div @click="showActivableOffers = !showActivableOffers" class="flex-div align-center justify-start">
        <v-icon class="cancel-flex d-block">local_offer</v-icon>
        <span class="ml-3 subtitle-1 grey--text text--darken-4">
          <b>{{ pendingOffers.length }}</b> nouvelle{{ pendingOffers.length > 1 ? 's' : '' }}
          offre{{ pendingOffers.length > 1 ? 's' : '' }} à activer.
        </span>
        <v-icon class="cancel-flex">{{ showActivableOffers ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
      </div>
      <v-expand-transition>
        <div v-if="showActivableOffers" class="w-100">
          <div class="flex-div vouchers-scroll" :class="{ 'justify-center': pendingOffers.length === 1 }">
            <OffersSlider :offers="pendingOffers" disable-header />
          </div>
        </div>
      </v-expand-transition>
    </v-alert>
  </div>
</template>

<script>
import OffersSlider from '@/components/OffersSlider'
import EventBus from '@/packs/event-bus'
import CustomerService from '@/services/CustomerService'

export default {
  components: {
    OffersSlider
  },
  data() {
    return {
      showActivableOffers: false,
      offersLoading: true,
      pendingOffers: []
    }
  },
  methods: {
    async fetchPendingOffers() {
      this.offersLoading = true
      this.pendingOffers = await CustomerService.getOffersPending(this.$route.params.id)
      this.offersLoading = false
    }
  },
  created() {
    this.fetchPendingOffers()
    EventBus.$on('reloadOffers', () => {
      this.pendingOffers = []
      this.fetchPendingOffers()
    })
  }
}
</script>
