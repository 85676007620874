<template>
  <div>
    <div v-if="loaded" class="w-100">
      <OffersSlider :offers="offers" v-if="offers.length > 0" />
      <OffersSlider :offers="vouchers" voucher v-if="vouchers.length > 0" />
    </div>
    <div class="d-flex" v-else>
      <v-progress-circular
        class="mx-auto mt-10"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import OffersSlider from '@/components/OffersSlider'
import EventBus from '@/packs/event-bus'
import CustomerService from '@/services/CustomerService'

export default {
  components: {
    OffersSlider
  },
  data() {
    return {
      loaded: false,
      offers: [],
      vouchers: []
    }
  },
  methods: {
    async fetchOffers() {
      this.loaded = false
      this.vouchers = []
      this.offers = []

      const res = await CustomerService.getOffers(this.$route.params.id)
      this.assignOffers(res)
      this.loaded = true
    },
    assignOffers(offers) {
      offers.forEach(offer => {
        if (offer.consumable.voucher) this.vouchers.push(offer)
        else this.offers.push(offer)
      })
    }
  },
  created() {
    this.fetchOffers()
    EventBus.$on('reloadOffers', () => { this.fetchOffers() })
  }
}
</script>
