<template>
  <v-card class="px-4 pt-2 pb-3" :elevation="2">
    <h3 class="mb-2">Filtres :</h3>
    <v-container class="pa-0 grid-list-md container--fluid">
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="4">
          <DateRangePicker @update="updateDateRange($event)"></DateRangePicker>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker'

export default {
  components: {
    DateRangePicker
  },
  data: function() {
    return {
      filters: {
        selector: Array,
        date: Object
      }
    }
  },
  methods: {
    updateDateRange(dateRange) {
      this.filters.date = dateRange
      this.$emit('filters', this.filters)
    },
    updateSelect(selector) {
      this.filters.selector = selector
      this.$emit('filters', this.filters)
    }
  }
}
</script>
