import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import LoginCustomer from '@/views/LoginCustomer.vue'
import Donate from '@/views/Donate.vue'
import Dashboard from '@/views/Dashboard.vue'
import RegisterAccount from '@/views/RegisterAccount.vue'
import ConfirmAccount from '@/components/ConfirmAccount.vue'
import WelcomeForm from '@/views/WelcomeForm.vue'
import ErrorPage from '@/views/ErrorPage.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import Unsubscribe from '@/views/Unsubscribe.vue'

import OfferDialog from '@/components/Dialogs/OfferDialog.vue'
import EditDialog from '@/components/Dialogs/EditDialog.vue'
import LoginDialog from '@/components/Dialogs/LoginDialog.vue'
import HistoryDialog from '@/components/Dialogs/HistoryDialog.vue'
import SettingsDialog from '@/components/Dialogs/SettingsDialog.vue'
import ConditionsDialog from '@/components/Dialogs/ConditionsDialog.vue'
import { getTokenPresence } from '@/services/AxiosWrapper'
import EmailConfirmedDialog from '@/components/Dialogs/EmailConfirmedDialog.vue'
import WelcomeDialog from '@/components/Dialogs/WelcomeDialog.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/404',
    name: '404',
    component: ErrorPage,
    meta: { auth: false }
  },
  {
    path: '/account/:subdomain',
    component: LoginCustomer,
    meta: { auth: false }
  },
  {
    path: '/account/:subdomain/create',
    component: WelcomeForm,
    meta: { auth: false }
  },
  {
    path: '/account/:subdomain/create/:action(new)/:apikey',
    component: WelcomeForm,
    meta: { auth: false },
    children: [{ path: 'conditions', name: 'conditionsWelcome', component: ConditionsDialog, meta: { auth: false } }]
  },
  {
    path: '/account/:subdomain/register/:identifier?',
    component: RegisterAccount,
    meta: { auth: false }
  },
  {
    path: '/account/:subdomain/confirm/:id?',
    component: ConfirmAccount,
    meta: { auth: false }
  },
  {
    path: '/donate/:subdomain/:voucher?',
    component: Donate,
    meta: { auth: false }
  },
  {
    path: '/:id',
    component: Dashboard,
    meta: { auth: false },
    name: 'wallet',
    children: [
      { path: 'offers/:offerId', component: OfferDialog, meta: { auth: false } },
      { path: 'edit', name: 'editCustomer', component: EditDialog, meta: { auth: true } },
      { path: 'history', name: 'historyCustomer', component: HistoryDialog, meta: { auth: true } },
      { path: 'settings', name: 'settingsCustomer', component: SettingsDialog, meta: { auth: true } },
      { path: 'login', name: 'loginCustomer', component: LoginDialog, meta: { auth: false } },
      { path: 'conditions', name: 'conditionsCustomer', component: ConditionsDialog, meta: { auth: false } },
      { path: ':confirm(confirmed)', name: 'confirmed', component: EmailConfirmedDialog, meta: { auth: false } },
      { path: ':confirm(welcome)', name: 'welcome', component: WelcomeDialog, meta: { auth: false } }
    ]
  },
  {
    path: '/:id/reset_password/:token',
    component: ResetPassword,
    meta: { auth: false }
  },
  {
    path: '/:id/unsubscribe',
    component: Unsubscribe,
    meta: { auth: false }
  },
  {
    path: '*',
    redirect: '/404',
    meta: { auth: false }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'loginCustomer' && getTokenPresence()) router.push({ name: 'wallet' }).catch(e => e)
  if ((to?.meta?.auth && getTokenPresence()) || !to?.meta?.auth) next()
  else router.push({ name: 'loginCustomer', query: { redirect_to: to.fullPath } }).catch(e => e)
})

export default router
