<template>
  <v-alert :value="show" transition="slide-y-transition" color="grey lighten-3" class="w-100 my-0 px-2">
    <v-row class="align-center">
      <v-col cols="1" @click="show = false" class="flex-div justify-center">
        <v-icon class="grey--text text--darken-4">close</v-icon>
      </v-col>
      <v-col cols="7" class="grey--text text--darken-4 pl-2">
        <h3>Ajouter à l'écran d'accueil</h3>
        <span>Profiter de votre carte de fidelité comme une application</span>
      </v-col>
      <v-col cols="4" class="flex-div justify-end">
        <v-btn @click="showInstallPromotion()" color="primary" class="secondary--text mr-1">
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  methods: {
    showInstallPromotion() {
      this.deferredPrompt.prompt()
      this.show = false
    }
  },
  created() {
    window.addEventListener('beforeinstallprompt', e => {
      this.show = true
      e.preventDefault()
      this.deferredPrompt = e
    })
  }
}
</script>
