<template>
  <v-container class="grid-list-lg container--fluid">
    <v-row class="justify-center" v-if="loaded">
      <v-col lg="7" sm="8" cols="12" class="mt-3">
        <v-row class="mb-3">
          <v-col md="2" sm="3" cols="3" v-if="logoUrl">
            <img :src="logoUrl" width="100%" alt="logo" />
          </v-col>
        </v-row>
        <v-card class="py-3">
          <v-row class="ma-4 justify-center">
            <v-col cols="12">
              <h2 class="text-center mt-2 font-weight-bold">{{ $t('sign_in_with_identifier.confirmTitle') }}</h2>
            </v-col>
            <v-col cols="12" class="my-3 d-flex justify-center">
              <img src="@/assets/img/undraw_waiting.svg" alt="illustration confirmation" height="240" />
            </v-col>
            <v-col md="12" cols="12">
              <h4 class="font-weight-light text-center">
                {{ companyName | capitalize }} {{ $t('sign_in_with_identifier.confirmTextMid') }}
                <i>{{ customerEmail ? customerEmail : '-' }}</i>.<br/>
                {{ $t('sign_in_with_identifier.confirmTextEnd') }}
              </h4>
              <span class="mt-4 d-block text-center grey--text text--darken-1">
                {{ $t('sign_in_with_identifier.confirmTextFail') }}
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyService from '@/services/CompanyService'
import CustomerService from '@/services/CustomerService'

export default {
  data() {
    return {
      loaded: false,
      identifier: null,
      subdomain: null,
      logoUrl: null,
      companyName: null,
      customerEmail: ''
    }
  },
  async created() {
    await CompanyService.getCompany(this.$route.params.subdomain).then(async res => {
      this.logoUrl = res.logo_url
      this.companyName = res.name
      await CustomerService.getByKey(this.$route.params.id).then(customer => {
        this.customerEmail = customer.email
        this.loaded = true
      })
    })
  }
}
</script>
