<template>
  <v-container class="fill-height container--fluid grid-list-xl">
    <v-row class="justify-center align-center">
      <v-col cols="12" sm="7" md="6">
        <v-card>
          <div class="flex-div flex-column align-center pa-4">
            <h1 class="text-center primary--text font-weight-regular">{{ errorMessage() }}</h1>
            <img src="@/assets/img/404.svg" alt="404 image" width="80%" class="mt-5" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    errorMessage() {
      switch (this.$route.query.errorType) {
        case 'apiKey':
          return "Cette Ecard n'existe pas"
        case 'subdomain':
          return "Le subdomain renseigné n'existe pas"
        default:
          return "Cette page n'existe pas"
      }
    }
  }
}
</script>
