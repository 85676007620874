<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="580">
    <v-card class="py-2">
      <v-btn icon class="close-icon-btn" @click.native="$emit('input')">
        <v-icon>close</v-icon>
      </v-btn>
      <div class="flex-div flex-column align-center pa-4">
        <h2 class="text-center my-3">
          {{ $t('customer_app.editPass.title') }}
        </h2>
        <v-form v-model="valid">
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            prepend-icon="lock"
            name="login"
            label="Entrer un nouveau mot de passe"
            :type="showPassword ? 'text' : 'password'"
            required
            @keyup.enter="editPass"
            color="primary"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            v-model="password_confirmation"
            :rules="passwordConfirmRules"
            prepend-icon="lock"
            name="login"
            label="Confirmer votre nouveau mot de passe"
            :type="showPasswordConfirmation ? 'text' : 'password'"
            required
            @keyup.enter="editPass"
            color="primary"
            :append-icon="showPasswordConfirmation ? 'visibility' : 'visibility_off'"
            @click:append="showPasswordConfirmation = !showPasswordConfirmation"
          ></v-text-field>
        </v-form>
        <v-btn class="mt-4" @click="editPass()" :disabled="!valid" color="primary">
          {{ $t('customer_app.setEmail.send') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/packs/event-bus'
import CustomerService from '@/services/CustomerService'

export default {
  data() {
    return {
      valid: true,
      showPassword: false,
      showPasswordConfirmation: false,
      password: '',
      password_confirmation: '',
      passwordRules: [
        v => !!v || 'Le nouveau mot de passe est requis',
        v => v.length > 7 || 'Le mot de passe doit contenir au moins 8 caractères'
      ],
      passwordConfirmRules: [
        v => !!v || 'La confirmation de mot de passe est requis',
        v => v === this.password || 'Les deux mots de passe saisis ne correspondent pas'
      ]
    }
  },
  props: {
    value: {
      type: Boolean
    },
    customer: {
      type: Object,
      required: true
    },
    subdomain: {
      type: String,
      required: true
    }
  },
  methods: {
    async editPass() {
      try {
        await CustomerService.editByKey(this.$route.params.id, {
          email: this.customer.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          subdomain: this.subdomain
        })
        EventBus.$emit('showSnackbar', {
          text: 'Mot de passe modifié avec succès',
          color: 'success'
        })
        this.$emit('input')
      } catch (err) {
        EventBus.$emit('showSnackbar', { text: err, color: 'error' })
      }
    }
  }
}
</script>
