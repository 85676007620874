import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    user: null,
    customer: null,
    owner: null
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_CUSTOMER(state, customer) {
      state.customer = customer
    },
    SET_OWNER(state, owner) {
      state.owner = owner
    }
  },
  actions: {
    clearUser(context) {
      context.commit('SET_USER', null)
    },
    setUser(context, payload) {
      context.commit('SET_USER', payload)
    },
    setCustomer(context, payload) {
      context.commit('SET_CUSTOMER', payload)
    },
    setOwner(context, payload) {
      context.commit('SET_OWNER', payload)
    },
    setLogoutTimer({ commit }, expirationTime) {
      setTimeout(() => {
        commit('clearUser')
      }, expirationTime * 1000)
    }
  },
  plugins: [createPersistedState()]
})
