<template>
  <v-card class="elevation-0 no-bg">
    <div class="my-2 flex-div align-center pt-1" v-if="!disableHeader">
      <img :src="sliderImagePath" crossorigin="anonymous" alt="Ecard" width="35" class="mr-2" />
      <h2 class="font-weight-light">{{ sliderTitle }}</h2>
    </div>
    <div class="flex-div vouchers-scroll">
      <div class="one-voucher" v-for="(offer, index) in offers" :key="index" @click.prevent="showOffer(offer)">
        <v-card class="flex-div flex-column h-100" v-if="offer">
          <v-card-title class="px-0 py-0">
            <div class="img-voucher-preview">
              <img :src="cardImage(offer)" crossorigin="anonymous" class="w-100" />
            </div>
            <div class="px-2 pt-2">
              <h4 class="text-center mb-1 text-break">
                {{
                  offer.consumable && offer.consumable.voucher && offer.consumable.voucher_name
                    ? offer.consumable.voucher_name
                    : ''
                }}
              </h4>
              <h5 class="mb-1 font-weight-medium text-center text-break" style="line-height: 1.5">
                {{ offerMessage(offer) }}
              </h5>
            </div>
            <div class="w-100 px-2 pb-1 text-center" v-if="offer.expire_at">
              <h6>Expiration: {{ $moment(offer.expire_at).calendar() }}</h6>
            </div>
          </v-card-title>
        </v-card>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    offers: {
      type: Array
    },
    voucher: {
      type: Boolean,
      required: false,
      default: false
    },
    disableHeader: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    showOffer(offer) {
      this.$router.push(`/${this.$route.params.id}/offers/${offer.id}`)
    },
    offerMessage(offer) {
      let message = offer.consumable.messages.fr
      const amount = offer.consumable.amount / 100
      const remaining_amount = offer.remaining_amount ? offer.remaining_amount / 100 : null

      return remaining_amount ? message.replace(amount, remaining_amount) : message
    },
    cardImage(offer) {
      if (offer.consumable.target && offer.consumable.target.logo_url) return offer.consumable.target.logo_url
      else if (offer.consumable.scope !== 'cart') return this.defaulItemImage
      else return this.defaulCartImage
    }
  },
  computed: {
    sliderTitle() {
      const title = this.voucher ? 'myVouchers' : 'myAdvantages'
      return this.$t(`customer_app.dashboard.${title}`)
    },
    sliderImagePath() {
      return this.voucher ? require('@/assets/img/coupons.svg') : require('@/assets/img/ecard.svg')
    },
    owner() {
      return this.$store.state.owner
    },
    defaulCartImage() {
      return this.owner.company.default_cart_image_url
        ? this.owner.company.default_cart_image_url
        : require('@/assets/img/fidelity-voucher.jpg')
    },
    defaulItemImage() {
      return this.owner.company.default_item_image_url
        ? this.owner.company.default_item_image_url
        : require('@/assets/img/fidelity-voucher.jpg')
    }
  }
}
</script>
