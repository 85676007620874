<template>
  <div v-if="relatedElements_">
    <div v-for="(familyMember, index) in relatedElements_" :key="index" class="mb-1 px-2">
      <v-row>
        <v-col cols="12" sm="6" md="2">
          <v-text-field v-model="familyMember.name" label="Nom" color="primary"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <DatePicker
            :initial-value="familyMember.birthdate"
            label="Date d'anniversaire"
            @update="familyMember.birthdate = $event"
          />
        </v-col>
        <v-col v-for="(custom_elem, index) in customAttributes" :key="index" style="width: max-content">
          <template v-if="custom_elem.type === 'text'">
            <v-text-field
              v-model="familyMember.custom_attributes[index]"
              :label="custom_elem.label"
              color="primary"
            ></v-text-field>
          </template>
          <template v-else-if="custom_elem.type === 'values'">
            <span class="text-no-wrap">{{ custom_elem.label }} :</span>
            <v-radio-group row hide-details v-model="familyMember.custom_attributes[index]" class="my-0 py-0">
              <v-radio
                v-for="(option, index) in custom_elem.custom_values"
                :label="option"
                :value="option"
                color="primary"
                :key="index"
              ></v-radio>
            </v-radio-group>
          </template>
          <template v-else-if="custom_elem.type === 'date'">
            <DatePicker label="Date d'anniversaire" @update="familyMember.custom_attributes.splice(index, 1, $event)" />
          </template>
          <template v-if="custom_elem.type === 'number'">
            <v-text-field
              class="text-number-simple"
              type="number"
              v-model.number="familyMember.custom_attributes[index]"
              :label="custom_elem.label"
              color="primary"
            ></v-text-field>
          </template>
        </v-col>
      </v-row>
    </div>
    <div class="mt-3 mb-1">
      <v-btn :disabled="relatedElements_.length >= 4" color="primary" @click="addMember()" small>
        <span v-if="owner.subdomain === 'moustaches'">{{ $t('customer_app.welcome.addPet') }}</span>
        <span v-else>Ajouter un membre</span>
      </v-btn>
      <v-btn v-if="relatedElements_.length > 1" color="primary" class="ml-2" small outlined @click="deleteMember()">
        <span v-if="owner.subdomain === 'moustaches'">{{ $t('customer_app.welcome.deletePet') }}</span>
        <span v-else>Supprimer un membre</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker'

export default {
  components: {
    DatePicker
  },
  props: {
    customerRelatedElements: {
      type: Array,
      required: true
    },
    customAttributes: {
      type: Array,
      required: true
    },
    owner: {
      type: Object,
      required: true
    }
  },
  computed: {
    relatedElements_: {
      get: function() {
        return this.customerRelatedElements ? this.customerRelatedElements : []
      },
      set: function(newValue) {
        this.$emit('RelatedElementChange', newValue)
      }
    }
  },
  methods: {
    addMember() {
      if (this.relatedElements_.length >= 5) return
      this.relatedElements_.push({ birthdate: this.$moment().format('YYYY-MM-DD'), custom_attributes: {} })
    },
    deleteMember() {
      this.relatedElements_.pop()
    }
  }
}
</script>

<style scoped>
.text-number-simple {
  width: 70px;
  flex: 0 1 auto;
}
</style>
