<template>
  <v-app class="test">
    <div id="app" class="h-100"><router-view class="h-100"></router-view></div>
    <v-snackbar :timeout="timeout" top :color="color" v-model="snackbar" rounded>{{ text }}</v-snackbar>
  </v-app>
</template>

<script>
import EventBus from '@/packs/event-bus'
import { lightOrDark } from '@/packs/lightOrDark.js'
import OwnerService from '@/services/OwnerService'

export default {
  name: 'app',
  data() {
    return {
      darkMode: false,
      //snackbar
      snackbar: false,
      text: '',
      color: '',
      timeout: 3000
    }
  },
  async created() {
    this.$moment.locale('fr')

    EventBus.$on('showSnackbar', snackbar => {
      this.showSnackbar(snackbar.text, snackbar.color)
    })

    const pathWithoutParams = this.$route.path.replace(this.$route.params.id, '')

    if (pathWithoutParams === '/') require('./registerServiceWorker')

    const faviconUrl = await this.setTheme()
    this.generateManifest(faviconUrl)
    this.generateFavicon(faviconUrl)
  },
  methods: {
    showSnackbar(text, color) {
      this.snackbar = true
      this.text = text
      this.color = color
    },
    async setTheme() {
      document.querySelectorAll("link[rel='icon']").forEach(e => e.parentNode.removeChild(e))
      return this.$route.params.id ? this.setThemeByApiKey() : this.setThemeBySubdomain()
    },
    async setThemeByApiKey() {
      try {
        const res = await OwnerService.getByKey(this.$route.params.id)
        this.$vuetify.theme.dark = res.company.dark_mode
        this.$vuetify.theme.themes.light.primary = res.company.main_color
        this.$vuetify.theme.themes.light.secondary = lightOrDark(res.company.main_color) === 'dark' ? '#ffffff' : '#424242'
        this.$vuetify.theme.themes.dark.primary = res.company.main_color
        this.$vuetify.theme.themes.dark.secondary = lightOrDark(res.company.main_color) === 'dark' ? '#ffffff' : '#424242'
        document.title = res.name

        return res.company.favicon_url
      } catch (e) {
        this.$router.push('/404?errorType=apiKey').catch(err => err)
      }
    },
    async setThemeBySubdomain() {
      try {
        const res = await OwnerService.get(this.$route.params.subdomain)
        this.$vuetify.theme.dark = res.dark_mode
        this.$vuetify.theme.themes.light.primary = res.main_color
        this.$vuetify.theme.themes.light.secondary = lightOrDark(res.main_color) === 'dark' ? '#ffffff' : '#424242'
        this.$vuetify.theme.themes.dark.primary = res.main_color
        this.$vuetify.theme.themes.dark.secondary = lightOrDark(res.main_color) === 'dark' ? '#ffffff' : '#424242'
        document.title = res.name

        return res.favicon_url
      } catch (e) {
        this.$router.push('/404?errorType=apiKey').catch(err => err)
      }
    },
    generateFavicon(faviconUrl) {
      let link = document.createElement('link')
      link.crossOrigin = 'anonymous'
      link.rel = 'icon'
      link.href = faviconUrl

      document.getElementsByTagName('head')[0].appendChild(link)
    },
    generateManifest(faviconUrl) {
      const ENV = process.env.VUE_APP_ENV
      const manifestGenUrl = `https://manifestgen.browseandgo.fr/bgmanifest.json?env=${ENV}&server=ecard&apiUrl=${this.$route.params.id}`

      document.querySelectorAll("[rel='manifest']")[0].setAttribute('href', manifestGenUrl)
      document.querySelectorAll("[rel='apple-touch-icon']")[0].setAttribute('href', faviconUrl)
    }
  }
}
</script>

<style lang="css">
body {
  margin: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

main {
  text-align: center;
  margin-top: 40px;
}
</style>
