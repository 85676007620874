<template>
  <div class="flex-div align-center w-100 h-100 shadow size-container">
    <div class="w-100 py-1" style="position: relative">
      <vc-date-picker
        class="w-100 custom-date-picker custom-placeholder"
        mode="range"
        v-model="date"
        @input="updateEvent()"
        :input-props="{
          style: 'border: 0;box-shadow: none; background: transparent',
          placeholder: $t('selector.periodFilter'),
          readonly: true
        }"
      />
      <v-icon v-if="date" class="reset-date" @click="clearEvent()" color="grey">clear</v-icon>
    </div>
    <div class="h-100 flex-div align-center pr-2" style="pointer-events: none">
      <v-icon class="ml-2">event</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: null
    }
  },
  methods: {
    updateEvent() {
      this.$emit('update', this.date)
    },
    clearEvent() {
      this.date = null
      this.updateEvent()
    }
  }
}
</script>

<style lang="sass">

.shadow
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)

.theme--dark
  .custom-placeholder
    ::placeholder
      color: white !important
      opacity: 1

    ::-ms-input-placeholder
      color: white !important

    ::-ms-input-placeholder
      color: white !important

    input
      color: white !important

.theme--light
  .custom-placeholder
    ::placeholder
      color: rgba(0,0,0,.87) !important
      opacity: 1

    ::-ms-input-placeholder
      color: rgba(0,0,0,.87) !important

    ::-ms-input-placeholder
      color: rgba(0,0,0,.87) !important

    input
      color: rgba(0,0,0,.87) !important

.reset-date
  position: absolute !important
  right: 12px
  top: calc(50% - 12px)

.size-container
  border-radius: 2px
  height: 48px
</style>
