Number.prototype.monetized = function() {
  return this / 100
}

Number.prototype.unmonetized = function() {
  return parseInt(this * 100)
}

Number.prototype.truncate = function() {
  return parseInt(this)
}
