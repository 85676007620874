<template>
  <div class="w-100 h-100 pa-4">
    <div class="subtitle-1">{{ $t('customer_app.dashboard.infoMessagePWA') }}</div>
    <div class="text-center text-lg-left">
      <v-btn :disabled="emailSent" @click="sendEmail()" class="cancel-flex secondary--text mt-4" color="primary">
        {{ $t('customer_app.dashboard.infoMessagePWAButton') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import CustomerService from '@/services/CustomerService'
import EventBus from '@/packs/event-bus'

export default {
  data() {
    return {
      emailSent: false
    }
  },
  methods: {
    async sendEmail() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('pass_customers_my_informations')
      try {
        await CustomerService.sendInformations(token, this.$route.params.id)
        this.emailSent = true
        EventBus.$emit('showSnackbar', { text: 'Email envoyé, vérifiez votre boite de réception', color: 'success' })
      } catch (err) {
        if (err.response.status && err.response.status === 429)
          EventBus.$emit('showSnackbar', { text: 'Trop de demandes, veuillez réessayer plus tard...', color: 'error'})
      }
    }
  }
}
</script>
